import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import MainApp from './MainApp';

const App = () => {
    return (
            <BrowserRouter basename="">
                <MainApp />
            </BrowserRouter>
    )
}

export default App;

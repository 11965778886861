import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import {  ListDepot, MapContainer, TableVerlauf } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { toast } from 'react-toastify';
import '../styles/toast.css'

const Verlauf = () => {

  const [center, setCenter] = useState([50.7169, 10.0371]); // Set it to the desired center of the map
  const [zoom, setZoom] = useState(6);  // Set the initial zoom level of the map

  const { depots, selectedDateTime, selectedDepot, setSelectedDepot, sortOrder, toggleSortOrder, selectedFilter } = useStateContext();
  const containers = [null];

  // UseState for the TableVerlauf component
  const [showTableVerlauf, setShowTableVerlauf] = useState(false);
  // Verlauf data (test) for the TableVerlauf component
  const [verlaufData, setVerlaufData] = useState([]);

  const fetchVerlaufData = useCallback((depot) => { // fetchVerlaufData function
    // Convert the selected start time to Unix time
    const startTimeUnix = Math.floor(selectedDateTime.startTime.getTime() / 1000)
    // Convert the selected end time to Unix time
    const endTimeUnix = Math.floor(selectedDateTime.endTime.getTime() / 1000);

    const dataToSend = {
      depot,
      start: startTimeUnix, 
      end: endTimeUnix, 
    };
    // Send a POST request to the API
    axios.post("https://clam-backend.pts.space/telemetry/depothistory", dataToSend, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
    .then((response) => {
      const responseData = JSON.parse(response.data); // Parse string to JSON

      if (responseData && Array.isArray(responseData.data)) { // Check if response data is an array
        const transformedData = responseData.data.map((item) => {
        
          const itemObj = {};
          responseData.columns.forEach((column, index) => {
            itemObj[column] = item[index];
          });
          return itemObj;
        });
        // Sort the transformedData array based on the sortOrder state
        transformedData.sort((a, b) => {
          if (a.time < b.time) {
            return sortOrder === 'asc' ? -1 : 1;
          }
          if (a.time > b.time) {
            return sortOrder === 'asc' ? 1 : -1;
          }
          return 0;
        });

        setVerlaufData(transformedData);
        setShowTableVerlauf(true);
        setCenter([selectedDepot.latitude, selectedDepot.longitude]);
        setZoom(12);
      } else {
        console.error("Unexpected response data format");
      }
    });
  }, [selectedDateTime.startTime, selectedDateTime.endTime, sortOrder, selectedDepot]);

  useEffect(() => {
    if (selectedDateTime.startTime && selectedDateTime.endTime && selectedDepot !== null) {
      if (selectedDateTime.startTime.getTime() < selectedDateTime.endTime.getTime()) {
        fetchVerlaufData(selectedDepot.name);
      } else {
        toast.error('Bitte überprüfen Sie die ausgewählte Zeitraum.', {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setVerlaufData([]);
      }
    } else if (!selectedDateTime.startTime && selectedDepot !== null) {
      toast.warn('Bitte wählen Sie ein Anfangsdatum und eine Uhrzeit aus.', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
        setVerlaufData([]);
    } else if (!selectedDateTime.endTime && selectedDepot !== null) {
      toast.warn('Bitte wählen Sie ein Enddatum und eine Uhrzeit aus.', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
        setVerlaufData([]);
      }
  }, [selectedDateTime.startTime, selectedDateTime.endTime, selectedDepot, fetchVerlaufData]);

  const handleDepotSelect = (depot) => {
    if (selectedDepot && selectedDepot.name === depot.name) {
      setSelectedDepot(null);
      setShowTableVerlauf(false);
      setCenter([50.7169, 10.0371]);
      setZoom(6);
    } else {
      setSelectedDepot(depot);
      setShowTableVerlauf(true);
      setCenter([depot.latitude, depot.longitude]);
      setZoom(12);
    }
  };

  let filteredVerlaufData = verlaufData;
  if (selectedFilter !== 'Alle') {
    filteredVerlaufData = verlaufData.filter((item) => item.container.includes(selectedFilter) );
  }

  return (
    <div className="mx-4 h-screen">
      <div className="flex flex-wrap md:flex-nowrap h-full">
        <div className="mr-4 mb-4 flex justify-start" style={{ width: '25%'}}>
          <ListDepot
            depots={depots}
            onSelect={handleDepotSelect}
            selectedDepot={selectedDepot}
          />
        </div>

        <div className='flex flex-col h-full w-full'>
          <div className="flex-grow h-1/2 w-full mb-2">
            <MapContainer 
            devices={containers} 
            depots={selectedDepot ? [selectedDepot] : depots} 
            fremdnutzungs={[]} 
            center={ center }
            zoom={ zoom } 
            clusterType={"red-blue"} 
            waypoints={[]} 
            onDepotSelect={handleDepotSelect}/>
          </div>
          {showTableVerlauf && (
            <div className="flex h-1/2 w-full justify-center mb-4">
              <div className='w-full'>
                <TableVerlauf data={filteredVerlaufData} toggleSortOrder={toggleSortOrder} sortOrder={sortOrder}/>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Verlauf;
import React from 'react';

const TableVerlauf = ({ data, toggleSortOrder, sortOrder }) => {
  const noData = data.length === 0;
  return (
    <div className="flex flex-col bg-gray-200 dark:bg-slate-700 shadow-md rounded-md p-2 max-h-full overflow-auto mb-4">
      <div className='mb-1 mr-1'>
        <table className="table-auto w-full">
          <thead>
            <tr className='text-center'>
              <th className="border-2 border-gray-700 dark:border-slate-100 px-4 py-2 dark:text-white w-1/3">
                <div className='flex justify-center'>
                  <span className='mr-2'>Wechselbrücke</span>
                </div>
              </th>
              <th className="border-2 border-gray-700 dark:border-slate-100 px-4 py-2 dark:text-white w-1/3">Zeit
                <button onClick={toggleSortOrder} className="bg-transparent border-none cursor-pointer ml-2">
                  <span className='text-xs'>{sortOrder === 'asc' ? '▼' : '▲'}</span>
                </button>
              </th>
              <th className="border-2 border-gray-700 dark:border-slate-100 px-4 py-2 dark:text-white w-1/3">Aktion</th>
            </tr>
          </thead>
        </table>
      </div>
      
      <div className='overflow-auto flex-grow'>
        <table className="table-auto w-full">
          <tbody>
            {!noData && data.map((item, index) => (
              <tr key={index} className={`bg-gray-300 dark:bg-slate-800 text-center dark:text-white`}>
                <td className="border border-gray-600 dark:border-slate-500 px-4 py-2 w-1/3">{item.customer_id}</td>
                <td className="border border-gray-600 dark:border-slate-500 px-4 py-2 w-1/3">{new Date(item.time).toLocaleString("de-DE")}</td>
                <td className="border border-gray-600 dark:border-slate-500 px-4 py-2 w-1/3">{item.action}</td>
              </tr>
            ))}
            {noData && (
              <tr className="bg-gray-300 dark:bg-slate-800 text-center dark:text-white">
              <td className="border border-gray-600 dark:border-slate-500 px-4 py-2 w-full" colSpan="3">Keine Wechselbrücke mit CLAM Einheit für den ausgewählten Zeitraum zu finden.</td>
            </tr>
            )}
          </tbody>
        </table>
      </div>
        
    </div>
  );
};

export default TableVerlauf;
import React from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { EditDepot } from "./";
import { MdOutlineRadioButtonChecked } from "react-icons/md";
import { MdOutlineRadioButtonUnchecked } from "react-icons/md";

const ListKunden = ({ depots }) => {
  const { isDepotEditOpen, handleDepotEditOpen, setDepotsUpdated } = useStateContext();
  return (
    <div className="w-full max-h-screen overflow-y-auto px-2 bg-gray-200 dark:bg-slate-700 dark:text-white rounded-lg shadow-md">
      <div className="flex justify-between items-center sticky top-0 bg-gray-200 dark:bg-slate-700 p-2">
        <h3 className="font-bold">Aktuelle Stammdaten:</h3>
        <button
          className="bg-[#A82F42] hover:bg-[#C7384E] text-white rounded py-1 text-sm px-2"
          onClick={() => handleDepotEditOpen()}
        >
          Aktualisieren
        </button>
      </div>
      <table className="table-auto border-2 border-gray-700 dark:border-slate-500 w-full mb-2 ">
        <thead className={isDepotEditOpen ? "" : "sticky top-11"}>
          <tr className=" bg-gray-400 dark:bg-slate-900 text-center dark:text-white">
            <th className="border border-gray-700 dark:border-slate-500 px-5 py-2">
              Name
            </th>
            <th className="border border-gray-700 dark:border-slate-500 px-5 py-2">
              Adresse
            </th>
            <th className="border border-gray-700 dark:border-slate-500 px-5 py-2">
              Typ
            </th>
            <th className="border border-gray-700 dark:border-slate-500 px-5 py-2">
              Betreiber
            </th>
            <th className="border border-gray-700 dark:border-slate-500 px-5 py-2">
              Bestand
            </th>
            <th className="border border-gray-700 dark:border-slate-500 px-5 py-2">
              Aktiv
            </th>
          </tr>
        </thead>
        <tbody>
          {depots.map((depot, index) => (
            <tr
              key={index}
              className={`bg-gray-300 dark:bg-slate-800 text-center dark:text-white`}
            >
              <td className="border border-gray-600 dark:border-slate-500 px-3 py-2">
                {depot.name}
              </td>
              <td className="border border-gray-600 dark:border-slate-500 px-3 py-2">
                {depot.address}
              </td>
              <td className="border border-gray-600 dark:border-slate-500 px-3 py-2">
                {depot.type}
              </td>
              <td className="border border-gray-600 dark:border-slate-500 px-3 py-2">
                {depot.operator}
              </td>
              <td className="border border-gray-600 dark:border-slate-500 px-3 py-2">
                {depot.units}
              </td>
              <td className="border border-gray-600 dark:border-slate-500 px-3 py-2">
                {depot.activity ? (
                  <span className=" flex items-center justify-center">
                    <MdOutlineRadioButtonChecked />
                  </span>
                ) : (
                  <span className=" flex items-center justify-center">
                    <MdOutlineRadioButtonUnchecked />
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isDepotEditOpen && <EditDepot setDepotsUpdated={setDepotsUpdated}/>}
    </div>
  );
};

export default ListKunden;

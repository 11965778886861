import React, { useState } from "react";
import { FaCamera } from "react-icons/fa";
import { FaBatteryFull } from "react-icons/fa";
import { FaBatteryEmpty } from "react-icons/fa";
import { useStateContext } from "../contexts/ContextProvider";
import { CameraWindow } from "./";

const TableEinheiten = ({ data, unitObj, toggleSortOrder, sortOrder }) => {
  const { isCamaraOpen, handleCamaraOpen, currentImageUrl } = useStateContext();
  const [expandedRow, setExpandedRow] = useState(null);

  const actionData = data.filter((item) => item.action !== null);

  const toggleRow = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  const getRowsBetweenActions = (currentAction, nextAction) => {
    const startIndex = data.findIndex(
      (item) => item.time === currentAction.time
    );
    const endIndex = nextAction
      ? data.findIndex((item) => item.time === nextAction.time)
      : data.length;
    const rows = data.slice(startIndex, endIndex);

    return rows.filter((item, index) => {
      if (index === 0) return true; // Always include the first row
      return item.door !== rows[index - 1].door; // Only include the row if the door status has changed
    });
  };

  return (
    <div className="flex flex-col bg-gray-200 dark:bg-slate-700 shadow-md rounded-md p-2 max-h-full overflow-auto mb-4">
      <div className="mb-1 mr-1">
        <table className="table-auto w-full">
          <thead>
            <tr className="text-center">
              <th className="border-2 border-gray-700 dark:border-slate-100 px-4 py-2 dark:text-white w-2/5">
                Zeit
                <button
                  onClick={toggleSortOrder}
                  className="bg-transparent border-none cursor-pointer ml-2 "
                >
                  <span className="text-xs">
                    {sortOrder === "asc" ? "▼" : "▲"}
                  </span>
                </button>
              </th>
              <th className="border-2 border-gray-700 dark:border-slate-100 px-4 py-2 dark:text-white w-3/5">
                <div className="flex justify-center">
                  <span className="mr-2">Aktionen für:</span>
                  <span className="font-normal mr-6">{unitObj.name}</span>
                  <button
                    onClick={() => {
                      if (/^CLAM-Mk3-\w+$/.test(unitObj.version)) {
                        const imageUrl = unitObj.lastImage ? `https://clam-backend.pts.space${unitObj.lastImage}` : null;
                        console.log(imageUrl);
                        handleCamaraOpen(imageUrl);
                      }
                    }}
                    className="cursor-pointer"
                  >
                    {/* Show the camara icon only if the condition is met */}
                    {/^CLAM-Mk3-\w+$/.test(unitObj.version) && <FaCamera />}
                  </button>
                  {/* Show the camera photo */}
                  {isCamaraOpen && (
                    <CameraWindow
                      image={currentImageUrl}
                      unitName={unitObj.name}
                    />
                  )}
                  {/* Show the battery icon */}
                  <div className="flex items-center ml-4 text-xl">
                    {unitObj.power ? <FaBatteryFull /> : <FaBatteryEmpty />}
                  </div>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>

      <div className="overflow-auto flex-grow">
        <table className="table-auto w-full">
          <tbody>
            {actionData.length > 0 &&
              actionData.map((item, index) => (
                <React.Fragment key={item.id || index}>
                  <tr className={`bg-gray-300 dark:bg-slate-800 text-center dark:text-white`}
                  >
                    <td className="border border-gray-600 dark:border-slate-500 px-4 py-2 w-2/5">
                      {new Date(item.time).toLocaleString("de-DE")}
                    </td>
                    <td className="border  border-gray-600 dark:border-slate-500 px-8 py-2 w-3/5 relative">
                      {item.action} <br /> <span className="text-xs italic text-gray-600 dark:text-gray-300">{item.address}</span>
                      <div className="flex items-center">
                        <button
                          onClick={() => toggleRow(index)}
                          className="cursor-pointer absolute right-3 text-center text-lg hover:font-bold top-1/2 transform -translate-y-1/2 flex items-center justify-center"
                        >
                          {expandedRow === index ? "-" : "+"}
                        </button>
                      </div>
                    </td>
                  </tr>
                  {expandedRow === index &&
                    getRowsBetweenActions(item, actionData[index + 1]).map(
                      (item, subIndex) => (
                        <tr
                          key={subIndex}
                          className="text-center bg-gray-500 dark:bg-slate-400 text-white dark:text-black"
                        >
                          <td className="border  border-gray-600 dark:border-slate-500 px-4 py-2 w-2/5">
                            {new Date(item.time).toLocaleString("de-DE")}
                          </td>
                          <td className="border  border-gray-600 dark:border-slate-500 px-4 py-2 w-3/5">
                            Auslastung: {item.vla} %<br />
                            Tür: {item.door}
                          </td>
                        </tr>
                      )
                    )}
                </React.Fragment>
              ))}
            {(actionData.length === 0 || actionData === null) && (
              <tr className="bg-gray-300 dark:bg-slate-800 text-center dark:text-white">
                <td
                  className="border border-gray-400 dark:border-slate-500 px-4 py-2 w-full"
                  colSpan="2"
                >
                  Keine Veränderungen für den ausgewählten Zeitraum zu zeigen.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableEinheiten;
import { Route, Routes } from 'react-router-dom';
import { Navbar, Sidebar, ArchiveWarnings, CreateDep, LegendeMenu } from './components';
import { Home, Warnungen, Status, Einheiten, Verlauf, Kunden } from './pages';
import { useStateContext } from './contexts/ContextProvider';
import './App.css';

const MainApp = () => {
    const { currentMode, selectedIcon, handleIconClick, handleResetSelectedIcon, isArchiveOpen, isDepotCreateOpen, isHelpOpen } = useStateContext();

    return (
        <div className={currentMode === 'Dark' ? 'dark' : ''}>
        
          <div className="flex flex-col pt-16 pl-24 relative bg-white dark:bg-slate-950 w-full min-h-screen">
            {/* Navbar */}
            <div className="fixed top-0 left-0 right-0 h-16 z-[10000] bg-white dark:bg-slate-950">
              <Navbar handleResetSelectedIcon={handleResetSelectedIcon} />
            </div>
            
            <div className="flex flex-1 overflow-hidden">
              {/* Sidebar */}
              <div className="fixed top-16 left-0 right-0 z-[6000] max-h-screen bottom-0 w-24 dark:bg-slate-900 bg-gray-200 rounded-r-lg">
                <Sidebar handleIconClick={handleIconClick} selectedIcon={selectedIcon} />
              </div>

              {/* Archive */}
              {isArchiveOpen && <ArchiveWarnings />} 
              {/* Depot Create*/}
              {isDepotCreateOpen && <CreateDep />}
              {/* Help */}
              {isHelpOpen && <LegendeMenu />} 

              {/* Main Content */}
              <div className="flex flex-col flex-1"> {/* flex-1 allows an item to grow and shrink as needed */}
                <Routes>
                  {/* Dashboard... each Element is a page on its own */}
                  <Route exact path="/" element={ <Home /> } />
                  <Route exact path="/home" element={ <Home /> } />
                  <Route exact path="/warnungen" element={ <Warnungen /> } />
                  <Route exact path="/status" element={ <Status /> } />
                  <Route exact path="/einheiten" element={ <Einheiten /> } />
                  <Route exact path="/verlauf" element={ <Verlauf /> } />
                  <Route exact path="/kunden" element={ <Kunden /> } />

		            </Routes>
              </div>
            </div>
          </div>
        
      </div>
    );
};

export default MainApp;
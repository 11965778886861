import React from 'react';

const ConfirmationWindow = ({ isOpen, onConfirm, onCancel, text }) => {
    if (!isOpen) {
        return null;
    }
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-[10000] bg-half-transparent">
            <div className="bg-white dark:bg-slate-800 p-4 rounded shadow">
                <p className='font-bold text-sm'>{text}</p>
                <div className="flex justify-end mt-4">
                    <button
                        className="bg-gray-200 hover:bg-gray-300 dark:bg-slate-700 dark:hover:bg-slate-500 px-4 py-2 rounded mr-2"
                        onClick={onCancel}
                    >Abbrechen
                    </button>
                    <button
                        className="bg-[#A82F42] hover:bg-[#C7384E] text-white px-4 py-2 rounded"
                        onClick={onConfirm}
                    >Bestätigen
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmationWindow;
import React from 'react';
import { Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import logo from '../data/Logo_home.svg';
import { Menu, Searchbar, DateTimePicker } from '.';
import { useStateContext } from '../contexts/ContextProvider';

const Navbutton = ({ title, customFunc, icon, color }) => {
  return(
  <div title={title}>
    <button
    type='button'
    onClick={ () => customFunc()}
    style={{color}} 
    className="flex items-center justify-center text-xl w-10 h-10 rounded-full bg-gray-200 hover:bg-gray-300 dark:bg-slate-700 dark:hover:bg-slate-500 text-gray-600 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-slate-400">
      {icon}
    </button>
  </div>
  );
};

const Navbar = () => {
  const { handleClick, isClicked, currentMode, handleSearchClick, handleLogoClick, setSelectedDateTime, selectedDateTime } = useStateContext();

  const handleDateTimeChanged = (id, value) => {
    setSelectedDateTime((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleDateTimePickerBlur = (id) => {
    handleClick(id, false);
  };

  return (
    <div className='flex justify-between p-2 md:mx-2 relative'>
      {/* Render the Logo */}
      <div className='flex items-center ml-2'>
        <Link to='/' onClick={()=> handleLogoClick()}>
          <img src={logo} alt="logo" className="w-52" /> 
        </Link>
        {/* <span className="text-gray-700 dark:text-white text-sm font-medium p-2">Dashboard</span> */}

      </div>
      <div className='flex items-center space-x-1 flex-grow justify-center'>
        {/* "Start" DateTimePicker Component */} 
        <div className="h-full flex items-center">
          {/* Always display the Start DateTimePicker component */}
          <DateTimePicker
            id="startTime"
            label="Anfang: Datum Uhrzeit"
            value={selectedDateTime.startTime}
            onChanged={handleDateTimeChanged}
            onDatePickerBlur={handleDateTimePickerBlur}
          />
        </div>
        <span className="text-gray-700 dark:text-white text-lg font-semibold text-center px-3">-</span>
        {/* "End" DateTimePicker Component */} 
        <div className="h-full flex items-center">
          {/* Always display the End DateTimePicker component */}
            <DateTimePicker
              id="endTime"
              label="Ende: Datum Uhrzeit"
              value={selectedDateTime.endTime}
              onChanged= {handleDateTimeChanged}
              onDatePickerBlur={handleDateTimePickerBlur}
            />
        </div>         
      </div>

      {/* Render the searchbar and the Menu Button */}
      <div className='flex items-center space-x-2 justify-end'>
        <Searchbar  handleSearchClick={handleSearchClick}/>
        <Navbutton title="Menu" customFunc={() => {handleClick('menu')}} icon={<FiMenu />} color={currentMode === 'Dark' ? "white" : "black"} />
      </div>
      {isClicked.menu && <Menu handleClick={handleClick} />}
      {isClicked.search && <Searchbar />}

    </div>
  );
}
export default Navbar;
import React from 'react';
import { MapContainer } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  
  const defaultCenter = [50.7169, 10.0371];
  const defaultZoom = 6; 
  const { validDevices, depots, setSelectedDepot, setSelectedEinheit, handleIconClick } = useStateContext();

  const navigate = useNavigate();

  const handleDepotClick = (depot) => {
    setSelectedDepot(depot);
    handleIconClick('status');
    navigate(`/status`);
  };

  const handleEinheitClick = (einheit) => {
    setSelectedEinheit(einheit);
    handleIconClick('units');
    navigate(`/einheiten`);
  };
  
  return (
    <div className="w-full z-10 h-full flex justify-center items-center pb-4">
      <div className="h-screen w-full mx-4">
          <MapContainer 
          devices={ validDevices } 
          depots={ depots } 
          fremdnutzungs={[]} 
          center={ defaultCenter }
          zoom={ defaultZoom } 
          clusterType={"red-blue"} 
          waypoints={[]}
          handleDepotClick={handleDepotClick}
          handleEinheitClick={handleEinheitClick}/>
      </div>
    </div>
    
  )
}
export default Home;
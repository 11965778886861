import React, { useState, useEffect } from 'react';
import { ListWarnings, MapContainer } from '../components';
import { useStateContext } from '../contexts/ContextProvider';

const Warnungen = () => {
  const [center, setCenter] = useState([50.9169, 9.0371]); // Set it to the desired center of the map
  const [zoom, setZoom] = useState(6);  // Set the initial zoom level of the map
  const depots = [null];
  const { warnings, validDevices } = useStateContext();

  const [warningsWithLocation, setWarningsWithLocation] = useState([]);
  const [selectedWarning, setSelectedWarning] = useState(null);
  const [selectedCompletedWarning, setSelectedCompletedWarning] = useState(null);
  // Add location to warnings using the valid devices from the List of Units (GET /telemetry/units)
  useEffect(() => {
    if (warnings.length && validDevices.length) {
      const updatedWarnings = warnings.map((warning) => {
        const locationDevice = validDevices.find(
          (device) => device.id === warning.unitId
        );
        return {
          ...warning,
          latitude: locationDevice?.latitude,
          longitude: locationDevice?.longitude,
          timestamp: locationDevice?.timestamp,
          activity: locationDevice?.activity,
          power: locationDevice?.power,
        };
      });
      setWarningsWithLocation(updatedWarnings);
    }
  }, [warnings, validDevices]);
  // Filter warnings with location and without next depot. This warnings will be displayed in the current warnings list.
  const currentWarnings = warningsWithLocation.filter(
    (warning) =>
      warning.latitude &&
      warning.longitude &&
      warning.nextDepot === null &&
      warning.nextDepotArrival === null
  );
  // Filter out warnings that have no location or no next depot. This warnings will be displayed in the completed warnings list.
  const completedWarnings = warningsWithLocation.filter(
    (warning) =>
      warning.latitude &&
      warning.longitude &&
      warning.nextDepot !== null &&
      warning.nextDepotArrival !== null
  );
  // Handle the selection of a warning in the current or completed warnings list.
  const handleWarningSelect = (warnings, warning) => {
    if (warnings === "current") {
      setSelectedWarning(warning);
      setSelectedCompletedWarning(null);
      setCenter(warning ? [warning.latitude, warning.longitude] : [50.9169, 9.0371]);
      setZoom(warning ? 12 : 6);
    } else if (warnings === "completed") {
      setSelectedWarning(null);
      setSelectedCompletedWarning(warning);
      setCenter([50.9169, 9.0371]);
      setZoom(6);
    }
  };

  return (
    <div className="mx-4 flex">
      <div className="flex flex-col mr-2 mb-2 h-screen w-full overflow-auto max-h-screen"> 
        <div className="flex flex-col flex-1 overflow-auto">
          <ListWarnings
            title="Aktuelle"
            warnings={currentWarnings}
            onSelect={(warning) => handleWarningSelect("current", warning)}
            selectedWarning={selectedWarning}
          />
        </div>
        <div className="my-2"></div>
        <div className="flex flex-col flex-1 overflow-auto">
          <ListWarnings
            title="Abgeschlossen"
            warnings={completedWarnings}
            onSelect={(warning) => handleWarningSelect("completed", warning)}
            selectedWarning={selectedCompletedWarning}
          />
        </div>
      </div>
      <div className="flex-grow w-full mb-2">
        <MapContainer
          devices={selectedWarning ? [selectedWarning] : currentWarnings}
          depots={depots}
          fremdnutzungs={[]}
          center={ center }
          zoom={ zoom }
          clusterType={"red-blue"}
          waypoints={[]}
        />
      </div>
    </div>
  );
};

export default Warnungen;
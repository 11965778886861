import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AiOutlineHome } from 'react-icons/ai';
import { AiOutlineHistory } from 'react-icons/ai';	
import { RiAlarmWarningLine } from 'react-icons/ri';
import { MdOutlineWarehouse } from 'react-icons/md';
import { RiUserSearchLine } from 'react-icons/ri';
import { useStateContext } from '../contexts/ContextProvider';
import wechselbrueckeIcon from '../data/wechselbruecke.svg';
import wechselbrueckeIconSelected from '../data/wechselbrueckewhite.svg';

const Sidebar = () => {
  const location = useLocation();
  const activeIcon = 'items-center flex-col justify-center gap-1 mt-1 flex text-2xl font-extrabold hover:bg-[#C7384E] hover:text-white tracking-tight dark:text-white text-slate-900 rounded-md w-full p-1';
  const normalIcon = 'items-center flex-col justify-center gap-1 mt-1 flex text-2xl font-normal tracking-tight dark:text-white text-slate-950 w-full p-1';
  const selectedIconStyle = {
    borderRadius: '5px',
    backgroundColor: '#A82F42',
    color: '#FFFFFF',
  };
  const selectedTextStyle = {
    fontWeight: 'bold',
    color: '#FFFFFF',
  };

  const [isHomeHovered, setHomeHovered] = useState(false);
  const [isWarnungenHovered, setWarnungenHovered] = useState(false);
  const [isStatusHovered, setStatusHovered] = useState(false);
  const [isEinheitenHovered, setEinheitenHovered] = useState(false);
  const [isVerlaufHovered, setVerlaufHovered] = useState(false);
  const [isKundenHovered, setKundenHovered] = useState(false);

  const handleHomeHover = () => {
    setHomeHovered(true);
  };
  const handleHomeLeave = () => {
    setHomeHovered(false);
  };
  const handleWarnungenHover = () => {
    setWarnungenHovered(true);
  };
  const handleWarnungenLeave = () => {
    setWarnungenHovered(false);
  };
  const handleStatusHover = () => {
    setStatusHovered(true);
  };
  const handleStatusLeave = () => {
    setStatusHovered(false);
  };
  const handleEinheitenHover = () => {
    setEinheitenHovered(true);
  };
  const handleEinheitenLeave = () => {
    setEinheitenHovered(false);
  };
  const handleVerlaufHover = () => {
    setVerlaufHovered(true);
  };
  const handleVerlaufLeave = () => {
    setVerlaufHovered(false);
  };
  const handleKundenHover = () => {
    setKundenHovered(true);
  };
  const handleKundenLeave = () => {
    setKundenHovered(false);
  };

  const { handleIconClick, selectedIcon, currentMode } = useStateContext();
  return (

    <div className= "mx-1 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10" >
      {/* Icon and Link to Home */}
      <div className="flex justify-between items-center"
      onClick={ () => handleIconClick('home')}
      onMouseEnter={handleHomeHover}
      onMouseLeave={handleHomeLeave}>
        <Link to="/home" className={(isHomeHovered || location.pathname.includes("/home")) ? activeIcon : normalIcon} style={(selectedIcon === 'home' || location.pathname.includes("/home")) ? selectedIconStyle : {}}>
          <AiOutlineHome /> <span className={(isHomeHovered || location.pathname.includes("/home")) ? "text-xs font-extrabold" : "text-xs"} style={(selectedIcon === 'home' || location.pathname.includes("/home")) ? selectedTextStyle : {}}>Home</span> 
        </Link>
      </div>
      {/* Icon and Link to Warnings */}
      <div className="flex justify-between items-center mt-1"
      onClick={ () => handleIconClick('warnings')}
      onMouseEnter={handleWarnungenHover}
      onMouseLeave={handleWarnungenLeave}>
        <Link to="/warnungen" className={(isWarnungenHovered || location.pathname.includes("/warnungen")) ? activeIcon : normalIcon} style={(selectedIcon === 'warnungen' || location.pathname.includes("/warnungen")) ? selectedIconStyle : {}}>
          <RiAlarmWarningLine /> <span className={(isWarnungenHovered || location.pathname.includes("/warnungen")) ? "text-xs font-extrabold" : "text-xs"} style={(selectedIcon === 'warnungen' || location.pathname.includes("/warnungen")) ? selectedTextStyle : {}}>Fremdnutzung</span> 
        </Link>
      </div>
      {/* Icon and Link to Units */}
      <div className="flex justify-between items-center mt-1"
      onClick={ () => handleIconClick('units')}
      onMouseEnter={handleEinheitenHover}
      onMouseLeave={handleEinheitenLeave}>
        <Link to="/einheiten" className={(isEinheitenHovered || location.pathname.includes("/einheiten")) ? activeIcon : normalIcon} style={(selectedIcon === 'units' || location.pathname.includes("/einheiten")) ? selectedIconStyle : {}}>
          <img src={currentMode === 'Dark' || selectedIcon === 'units' || location.pathname.includes("/einheiten") || isEinheitenHovered ? wechselbrueckeIconSelected : wechselbrueckeIcon } alt="Truck" className="inline-block w-7 h-5 mt-1"/> 
          <span className={(isEinheitenHovered || location.pathname.includes("/einheiten")) ? "text-xs font-extrabold" : "text-xs"} style={(selectedIcon === 'units' || location.pathname.includes("/einheiten")) ? selectedTextStyle : {}}>CLAM-Einheit</span> 
        </Link>
      </div>
      {/* Icon and Link to Status */}
      <div className="flex justify-between items-center mt-1"
      onClick={ () => handleIconClick('status')}
      onMouseEnter={handleStatusHover}
      onMouseLeave={handleStatusLeave}>
        <Link to="/status" className={(isStatusHovered || location.pathname.includes("/status")) ? activeIcon : normalIcon} style={(selectedIcon === 'status' || location.pathname.includes("/status")) ? selectedIconStyle : {}}>
          <MdOutlineWarehouse /> <span className={(isStatusHovered || location.pathname.includes("/status")) ? "text-xs font-extrabold" : "text-xs"} style={(selectedIcon === 'status' || location.pathname.includes("/status")) ? selectedTextStyle : {}}>Status Depot</span> 
        </Link>
      </div>
      {/* Icon and Link to History */}
      <div className="flex justify-between items-center mt-1"
      onClick={ () => handleIconClick('history')}
      onMouseEnter={handleVerlaufHover}
      onMouseLeave={handleVerlaufLeave}>
        <Link to="/verlauf" className={(isVerlaufHovered || location.pathname.includes("/verlauf")) ? activeIcon : normalIcon} style={(selectedIcon === 'history' || location.pathname.includes("/verlauf")) ? selectedIconStyle : {}}>
          <AiOutlineHistory /> <span className={(isVerlaufHovered || location.pathname.includes("/verlauf")) ? "text-xs font-extrabold" : "text-xs"} style={(selectedIcon === 'history' || location.pathname.includes("/verlauf")) ? selectedTextStyle : {}}>Verlauf Depot</span> 
        </Link>
      </div>
      {/* Icon and Link to Clientlist */}
      <div className="flex justify-between items-center mt-1"
      onClick={ () => handleIconClick('clients')}
      onMouseEnter={handleKundenHover}
      onMouseLeave={handleKundenLeave}>
        <Link to="/kunden" className={(isKundenHovered || location.pathname.includes("/kunden")) ? activeIcon : normalIcon} style={(selectedIcon === 'clients' || location.pathname.includes("/kunden")) ? selectedIconStyle : {}}>
          <RiUserSearchLine /> <span className={(isKundenHovered || location.pathname.includes("/kunden")) ? "text-xs font-extrabold" : "text-xs"} style={(selectedIcon === 'clients' || location.pathname.includes("/kunden")) ? selectedTextStyle : {}}>Stammdaten</span> 
        </Link>
      </div>
    
    </div>
  )
}
export default Sidebar;
import React from 'react';
import { useStateContext } from '../contexts/ContextProvider';

const CreateDepot = () => {
    const { handleDepotCreateClose } = useStateContext();
    return (
        <div className='bg-half-transparent z-[8000] h-screen w-full fixed nav-item top-0 left-0 flex justify-center'>
            <div className='mt-24 rounded-lg h-fit text-gray-800 dark:text-white bg-white dark:bg-slate-800 w-6/12 shadow'>
                <div className='flex justify-between items-center p-4 ml-4'>
                    <p className='font-semibold text-lg flex-grow text-center'>Depot Erstellen</p>
                </div>
                {/* Fill Formular for the creation of a Depot */}
                <div className='flex-col border-t-1 border-gray-700 dark:border-slate-200 p-2'>
                <div className='flex justify-center items-center text-sm p-2'>
                    <button
                        className="bg-gray-200 hover:bg-gray-300 dark:bg-slate-700 dark:hover:bg-slate-500 px-4 py-2 rounded mr-2"
                        onClick={()=> handleDepotCreateClose()}
                    >Abbrechen
                    </button>
                    <button
                        className="bg-[#A82F42] hover:bg-[#C7384E] text-white px-4 py-2 rounded"
                        onClick={()=> {}}
                    >Bestätigen
                    </button>
                </div>
                </div>
            </div>
        </div>
    )
}

export default CreateDepot;

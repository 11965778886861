import React, { useState, useCallback, useEffect } from 'react';
import { MapContainer, ListContainer, TableEinheiten } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { toast } from 'react-toastify';
import '../styles/toast.css'

const Einheiten = () => {

  const [center, setCenter] = useState([50.7169, 10.0371]); // Set it to the desired center of the map
  const [zoom, setZoom] = useState(6);  // Set the initial zoom level of the map

  const { validDevices, selectedDateTime, selectedEinheit, setSelectedEinheit, depots, sortOrder, toggleSortOrder } = useStateContext();
  
  // UseState for the TableEinheiten component
  const [showTableEinheiten, setShowTableEinheiten] = useState(false);
  // Unitdata (test) for the TableEinheiten component
  const [unitData, setUnitData] = useState([]);
  // Points to draw the route on the map 
  const [waypoints, setWaypoints] = useState([]);
  // UseState for the MapContainer component to show the last known position of the selected unit
  const [lastKnownPosition, setLastKnownPosition] = useState(null);
  // UseState for the MapContainer component to show the last known position of a fremdnutzung
  const [lastFremdnutzung, setLastFremdnutzung] = useState(null);

  const fetchUnitData = useCallback((unit_id) => { // fetchUnitData function
    // Convert the selected start time to Unix time
    const startTimeUnix = Math.floor(selectedDateTime.startTime.getTime() / 1000)
    // Convert the selected end time to Unix time
    const endTimeUnix = Math.floor(selectedDateTime.endTime.getTime() / 1000);

    const dataToSend = {
      unit_id,
      start: startTimeUnix, 
      end: endTimeUnix, 
    };
    // Send a POST request to the API
    axios.post("https://clam-backend.pts.space/telemetry/unithistory", dataToSend, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
    .then((response) => {
      const responseData = JSON.parse(response.data); // Parse string to JSON

      if (responseData && Array.isArray(responseData.data)) { // Check if response data is an array
        const transformedData = responseData.data.map((item) => { 

          const itemObj = {};
          responseData.columns.forEach((column, index) => {
            itemObj[column] = item[index];
          });
          // Add the name property
          itemObj.name = selectedEinheit.name;
          itemObj.latitude = itemObj.lat;
          itemObj.longitude = itemObj.long;
          return itemObj;
        });
        // Sort the transformedData array based on the sortOrder state
        transformedData.sort((a, b) => {
          if (a.time < b.time) {
            return sortOrder === 'asc' ? -1 : 1;
          }
          if (a.time > b.time) {
            return sortOrder === 'asc' ? 1 : -1;
          }
          return 0;
        });
        // If the transformedData array is empty, show the UnitInactiveWindow component and set the lastKnownPosition state to null to hide it from the map
        if (transformedData.length === 0) {
          setLastKnownPosition(null);
          setShowTableEinheiten(true);
          setWaypoints([]);
          setUnitData([]);
          setZoom(5);
          setCenter([50.7169, 10.0371]);
        } else if (transformedData.length !== 0) {
          setUnitData(transformedData); // Set the transformed data to the unitData state to show it in the TableEinheiten component

          const matchingDevices = validDevices.find(device => device.id === unit_id); // Find the selected unit in the validDevices array
          const lastKnownPositionWithActivity = {
            ...transformedData[0],
            activity: matchingDevices ? matchingDevices.activity : false,
            timestamp: transformedData[0].time,
            power: matchingDevices ? matchingDevices.power : false,
          };
          //console.log(lastKnownPositionWithActivity);
          setLastKnownPosition(lastKnownPositionWithActivity); // Get the last known position and show if its active or not
          setWaypoints(transformedData.map(item => [item.latitude, item.longitude, item.time])); // Transform lat longs for waypoints
          setShowTableEinheiten(true); // Show the TableEinheiten component
          setZoom(10);
          setCenter([lastKnownPositionWithActivity.latitude, lastKnownPositionWithActivity.longitude]);
          }

      } else {
        console.error("Unexpected response data format");
      }
    });
  }, [selectedDateTime.endTime, selectedDateTime.startTime, selectedEinheit, sortOrder, validDevices]);

  useEffect(() => {
    if (selectedDateTime.startTime && selectedDateTime.endTime && selectedEinheit !== null) {
      if (selectedDateTime.startTime.getTime() < selectedDateTime.endTime.getTime()) {
        fetchUnitData(selectedEinheit.id);
      } else {
        toast.error('Bitte überprüfen Sie die ausgewählte Zeitraum.', {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
          setUnitData([]);
      }
    } else if (!selectedDateTime.startTime && selectedEinheit !== null) {
      toast.warn('Bitte wählen Sie ein Anfangdsatum und eine Uhrzeit aus.', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
        setUnitData([]);
    } else if (!selectedDateTime.endTime && selectedEinheit !== null) {
      toast.warn('Bitte wählen Sie ein Enddatum und eine Uhrzeit aus.', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
        setUnitData([]);
      }
  }, [selectedDateTime.startTime, selectedDateTime.endTime, selectedEinheit, fetchUnitData]);

  const handleEinheitSelect = (container) => {
    if (selectedEinheit && selectedEinheit.id === container.id) {
        // Unselect the container and hide the table if it's selected again.
        setSelectedEinheit(null);
        setShowTableEinheiten(false);
        setLastKnownPosition(null);
        setWaypoints([]);
        setLastFremdnutzung(null);
        //setZoom(6);
        //setCenter([50.7169, 10.0371]);
    } else {
        // If a new container is selected, fetch its data
        setSelectedEinheit(container);
        //setZoom(10);
        //setCenter([container.latitude, container.longitude]);
    }
  };

  useEffect(() => {
    if (selectedEinheit) {
      setZoom(10);
      setCenter([selectedEinheit.latitude, selectedEinheit.longitude]);
    } else {
      setZoom(6);
      setCenter([50.7169, 10.0371]);
    }
  }, [selectedEinheit]);

  useEffect(() => {
    const fremdnutzungs = unitData.filter(device => device.action === 'ankunft Fremdnutzung');
    const fremdnutzungsWithTimestamp = fremdnutzungs.map(device => ({
      ...device,
      timestamp: device.time,
    }));
    setLastFremdnutzung(fremdnutzungsWithTimestamp[fremdnutzungsWithTimestamp.length - 1]);
  }, [unitData]);

  return (
    <div className="mx-4 h-screen">
      <div className="flex flex-wrap md:flex-nowrap h-full">
        <div className="mr-4 mb-4 flex justify-start" style={{ width: '20%'}}>
          <ListContainer
            containers={validDevices}
            onSelect={handleEinheitSelect}
            selectedContainer={selectedEinheit}
          />
        </div>
        <div className='flex flex-col h-full w-full'>
          <div className="flex-grow h-1/2 w-full mb-2">
            <MapContainer 
            devices={lastKnownPosition ? [lastKnownPosition] : validDevices} 
            depots={depots} 
            fremdnutzungs={lastFremdnutzung ? [lastFremdnutzung] : []} 
            waypoints={waypoints} 
            center={ center }
            zoom={ zoom }
            clusterType={"red-blue"} 
            onDepotSelect={null}/>
          </div>
          {showTableEinheiten && (
            <div className="h-1/2 w-full flex justify-center mb-4">
              <div className="w-full">
                <TableEinheiten data={unitData} unitObj={selectedEinheit} toggleSortOrder={toggleSortOrder} sortOrder={sortOrder} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Einheiten;
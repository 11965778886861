import React from 'react'

const PopWindow = ({ isOpen, onConfirm }) => {
    if (!isOpen) {
        return null;
    }
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-[10000] bg-half-transparent">
            <div className="bg-white dark:bg-slate-700 p-4 rounded shadow">
                <p>Es gibt mehr als ein Report für diese CLAM-Einheit.</p>
                <div className="flex justify-end mt-4">
                    <button
                        className="bg-[#A82F42] hover:bg-[#C7384E] dark:bg-slate-500 dark:hover:bg-slate-700 text-white px-4 py-2 rounded"
                        onClick={onConfirm}>Ok
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PopWindow;
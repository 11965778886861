import React, { useState, useEffect } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { PopWindow } from ".";

const ArchiveWarnings = () => {
  const {
    handleArchiveClose,
    downloadReport,
    isPopWindowOpen,
    setPopWindowOpen,
  } = useStateContext();
  const [selectedWarning, setSelectedWarning] = useState(null);

  const [searchText, setSearchText] = useState("");

  const [closedwarnings, setClosedWarnings] = useState([]);
  useEffect(() => {
    const fetchClosedWarnungen = async () => {
      const response = await fetch(
        "https://clam-backend.pts.space/telemetry/fremdnutzung-archive"
      );
      const data = await response.json();
      // Transforming data right here
      const transformedWarnings = data.unit_customer_names.map(
        (name, index) => {
          return {
            id: data.id[index],
            unitId: data.unit_ids[index],
            name: name,
            lastDepot: data.last_depot[index],
            lastDepotDeparture: data.last_depot_departure[index],
            foreignLocation: data.foreign_location[index],
            foreignLocationArrival: data.foreign_location_arrival[index],
            foreignLocationDeparture: data.foreign_location_departure[index],
            nextDepot: data.next_depot[index],
            nextDepotArrival: data.next_depot_arrival[index],
          };
        }
      );
      setClosedWarnings(transformedWarnings);
    };
    fetchClosedWarnungen();
  }, []);

  const filteredWarnings = closedwarnings.filter((warning) =>
    warning.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="bg-half-transparent z-[100] h-screen w-full fixed nav-item left-0 flex justify-center items-center">
      <div className="mt-16 mb-28 rounded-lg h-3/4 text-gray-800 dark:text-white bg-white dark:bg-slate-800 w-6/12 flex flex-col shadow">
        <div>
          <div className="flex justify-between items-center p-4 ml-4">
            <div className="flex-grow flex justify-center">
              <p className="font-semibold text-lg text-center">
                Archiv Fremdnutzungen
              </p>
            </div>
          </div>

          <div className="flex-col border-t-1 border-gray-700 dark:border-slate-200 p-4 pb-1">
            <div className="relative flex flex-col items-center bg-gray-200 dark:bg-slate-600 rounded focus-within:ring-2 focus-within:ring-gray-400 dark:focus-within:ring-slate-400 z-[500]">
              <div className="flex items-center w-full bg-transparent">
                <input
                  type="text"
                  placeholder="Bearbeitete Fremdnutzung"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  className="w-full focus:outline-none p-2 rounded-l bg-transparent text-sm text-gray-600 dark:text-gray-200 placeholder-gray-500 dark:placeholder-white flex-grow placeholder:text-sm"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-y-auto flex-grow p-2 ml-2">
          <ul>
            {filteredWarnings.map((warning) => (
              <li
                key={warning.id}
                onClick={() => {
                  if (selectedWarning && selectedWarning.id === warning.id) {
                    setSelectedWarning(null);
                  } else {
                    setSelectedWarning(warning);
                  }
                }}
                className={`p-2 my-1 rounded cursor-pointer transition-colors duration-100 
                                ${
                                  selectedWarning &&
                                  selectedWarning.id === warning.id
                                    ? "bg-gray-300"
                                    : "hover:bg-gray-200 dark:hover:bg-slate-500"
                                }`}
              >
                <div className="flex justify-between">
                  <div className="flex justify-between w-full">
                    <div>{warning.name}</div>
                    <span className="text-gray-500">
                      {new Date(warning.foreignLocationArrival).toLocaleString(
                        "de-DE"
                      )}
                    </span>
                  </div>
                </div>
                {selectedWarning && selectedWarning.id === warning.id && (
                  <>
                    {/* Your detailed information section */}
                    <div className="mt-2 ml-2 text-sm flex flex-col dark:text-white">
                      {/* Unordered list with bullet points*/}
                      <div>
                        <div className="text-[16px] font-bold">Abfahrt:</div>
                        <ul className="list-disc list-outside ml-4">
                          <li>
                            Abfahrt:{" "}
                            {new Date(
                              warning.lastDepotDeparture
                            ).toLocaleString("de-DE")}
                          </li>
                          <li>Depot: {warning.lastDepot}</li>
                        </ul>
                      </div>
                      <div>
                        <div className="text-[16px] font-bold">Unbekannt:</div>
                        <ul className="list-disc list-outside ml-4">
                          <li>
                            Ankunft:{" "}
                            {new Date(
                              warning.foreignLocationArrival
                            ).toLocaleString("de-DE")}
                          </li>
                          <li>Ort: {warning.foreignLocation}</li>
                          <li>
                            Abfahrt:{" "}
                            {new Date(
                              warning.foreignLocationDeparture
                            ).toLocaleString("de-DE")}
                          </li>
                        </ul>
                      </div>
                      <div>
                        <div className="text-[16px] font-bold">Ankunft:</div>
                        <ul className="list-disc list-outside ml-4">
                          <li>
                            Ankunft:{" "}
                            {new Date(warning.nextDepotArrival).toLocaleString(
                              "de-DE"
                            )}
                          </li>
                          <li>Depot: {warning.nextDepot}</li>
                        </ul>
                      </div>
                      <div className="mt-2 flex gap-2 justify-center">
                        <button
                          className="bg-[#A82F42] hover:bg-[#C7384E] text-white rounded-md py-1 text-sm px-2"
                          onClick={(event) => {
                            event.stopPropagation();
                            downloadReport(selectedWarning.id, true);
                          }}
                        >
                          Report erstellen
                        </button>
                      </div>
                      <PopWindow
                        isOpen={isPopWindowOpen}
                        onConfirm={() => {
                          setPopWindowOpen(false);
                          downloadReport(selectedWarning.id, false);
                        }}
                      />
                    </div>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-center items-center p-2">
        <button
            className="bg-[#A82F42] hover:bg-[#C7384E] text-white text-sm px-4 py-2 rounded"
            onClick={()=> handleArchiveClose()}
        >Schließen
        </button>
      </div>
      </div>
    </div>
  );
};
export default ArchiveWarnings;

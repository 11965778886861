import React, { createContext, useContext, useState, useEffect } from "react";

const StateContext = createContext();

const initialState = {
  startTime: false,
  endTime: false,
  search: false,
  menu: false,
};

export const ContextProvider = ({ children }) => {
  // Icons for the navbar
  const [isClicked, setIsClicked] = useState(initialState);
  const handleClick = (clicked, value = true) =>
    setIsClicked({ ...initialState, [clicked]: value }); // default all to false, then set the clicked one to true
  // Theme mode for the whole app
  const [currentMode, setCurrentMode] = useState("Light"); // default to light mode
  const setMode = (mode) => {
    setCurrentMode(mode.target.value);
    localStorage.setItem("theme", mode.target.value);
  };
  // Logo for the navbar
  const handleLogoClick = () => {
    if (handleResetSeletedIcon) {
      handleResetSeletedIcon();
    }
  };
  const handleResetSeletedIcon = () => {
    setSelectedIcon(false);
  };
  // Icons for the sidebar
  const [selectedIcon, setSelectedIcon] = useState(false);
  const handleIconClick = (icon) => {
    setSelectedIcon(icon);
  };
  // DateTimePicker for the navbar
  const [selectedDateTime, setSelectedDateTime] = React.useState({
    startTime: new Date(Date.now() - 24 * 60 * 60 * 1000), // 24 hours before current time
    endTime: new Date(), // Current time
  });
  // ArchiveWarnings
  const [isArchiveOpen, setArchiveOpen] = useState(false);
  const handleArchiveOpen = () => {
    handleClick("menu", false);
    setArchiveOpen(true);
  };
  const handleArchiveClose = () => setArchiveOpen(false);
  // Create Depot
  const [isDepotCreateOpen, setDepotCreateOpen] = useState(false);
  const handleDepotCreateOpen = () => {
    handleClick("menu", false);
    setDepotCreateOpen(true);
  };
  const handleDepotCreateClose = () => setDepotCreateOpen(false);
  // Edit Depot
  const [isDepotEditOpen, setDepotEditOpen] = useState(false);
  const handleDepotEditOpen = () => {
    setDepotEditOpen(true);
  };
  const handleDepotEditClose = () => setDepotEditOpen(false);
  // Help Window
  const [isHelpOpen, setHelpOpen] = useState(false);
  const handleHelpOpen = () => {
    handleClick("menu", false);
    setHelpOpen(true);
  };
  const handleHelpClose = () => setHelpOpen(false);
  // Camara Window
  const [isCamaraOpen, setCamaraOpen] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState(null);
  const handleCamaraOpen = (imageUrl) => {
    setCurrentImageUrl(imageUrl);
    setCamaraOpen(true);
  };
  const handleCamaraClose = () => setCamaraOpen(false);

  // UseState for the popup window
  const [isPopWindowOpen, setPopWindowOpen] = useState(false);

  // basic devices state
  const [devices, setDevices] = useState([]);
  useEffect(() => {
    const fetchDevices = async () => {
      const response = await fetch(
        "https://clam-backend.pts.space/telemetry/units"
      );
      const data = await response.json();
      // perform the transformation right here
      const transformedDevices = data.unit_customer_names.map((name, index) => {
        return {
          id: data.unit_ids[index],
          name: name,
          latitude: data.latitude[index],
          longitude: data.longitude[index],
          timestamp: data.last_heard[index]
            ? new Date(data.last_heard[index])
            : "Kein Stempel",
          power: data.on[index], // 0 if the unit is offline
          activity: data.active[index], // 0  if the unit hasnt moved in the last 3 days
          version: data.pts_id[index],
          lastImage: data.last_img[index], // last image taken from the unit, contains the url path for the last image of that unit (e.g. "/media/unit1_12572416.jpg" you need to combine that with clam-backend.pts.space to clam-backend.pts.space/media/unit1_12572416.jpg)
        };
      });
      const sortedDevices = transformedDevices.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setDevices(sortedDevices);
    };
    fetchDevices();
  }, []); // Empty array to execute this effect only once when the component mounts
  // Filter out devices without coordinates (null values)
  const validDevices = devices.filter(
    (device) => device.latitude !== 0 && device.longitude !== 0
  ); // Filter devices without gps data.

  const [selectedFilter, setSelectedFilter] = useState("Alle");
  let filteredDevices = validDevices;
  if (selectedFilter !== "Alle") {
    filteredDevices = validDevices.filter((device) =>
      device.name.includes(selectedFilter)
    );
  }

  // basic depots state
  const [depots, setDepots] = useState([]);
  // state to check if depots have been updated
  const [depotsUpdated, setDepotsUpdated] = useState(false);
  useEffect(() => {
    const fetchDepots = async () => {
      const response = await fetch(
        "https://clam-backend.pts.space/telemetry/depots"
      );
      const data = await response.json();
      // Transforming data right here
      const transformedDepots = data.names.map((name, index) => ({
        id: index + 1,
        name: name,
        latitude: data.gps_latitude[index],
        longitude: data.gps_longitude[index],
        radius: data.radius[index],
        address: data.address[index],
        type: data.depot_type[index], // Depot oder Tagabholer
        operator: data.operator[index], // DPD, WBD, Zwichenlager
        units: data.unit_count[index], // amount of units to be expected at the depot
        activity: data.active[index], // 0 if the depot is inactive
      }));

      const sortedDepots = transformedDepots.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setDepots(sortedDepots);
      setDepotsUpdated(false);
    };
    fetchDepots();
  }, [depotsUpdated]);

  // basic warnings state
  const [warnings, setWarnings] = useState([]);
  async function fetchWarnungen() {
    const response = await fetch(
      "https://clam-backend.pts.space/telemetry/fremdnutzung"
    );
    const data = await response.json();
    // Transforming data right here
    const transformedWarnings = data.unit_customer_names.map((name, index) => {
      return {
        id: data.id[index],
        unitId: data.unit_ids[index],
        name: name,
        lastDepot: data.last_depot[index],
        lastDepotDeparture: data.last_depot_departure[index],
        foreignLocation: data.foreign_location[index],
        foreignLocationArrival: data.foreign_location_arrival[index],
        foreignLocationDeparture: data.foreign_location_departure[index],
        nextDepot: data.next_depot[index],
        nextDepotArrival: data.next_depot_arrival[index],
      };
    });
    setWarnings(transformedWarnings);
  }

  useEffect(() => {
    fetchWarnungen();
  }, []);

  const downloadReport = async (id, shouldShowPopWindow = false) => {
    const response = await fetch(
      "https://clam-backend.pts.space/telemetry/report",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      }
    );

    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

    const blob = await response.blob();

    const contentDisposition = response.headers.get("Content-Disposition");
    let filename = "file.pdf";
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="(.+)"/i);
      if (match && match.length > 1) {
        filename = match[1];
      }
    }

    // Check if there are multiple reports
    if (
      (filename.includes("_1-2") ||
        filename.includes("_1-3") ||
        filename.includes("_2-2") ||
        filename.includes("_2-3") ||
        filename.includes("_3-3")) &&
      shouldShowPopWindow
    ) {
      setPopWindowOpen(true);
    } else {
      // Create a link for the blob and click it to start download
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
    }
  };

  // UseState for selected depot in the ListDepot component
  const [selectedDepot, setSelectedDepot] = useState(null);
  // UseState for selected unit in the ListContainer component
  const [selectedEinheit, setSelectedEinheit] = useState(null);
  // UseState for the confirmation window
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const openConfirmationWindow = () => {
    setConfirmationOpen(true);
  };
  // Order on how the Information the Table is going to be displayed
  const [sortOrder, setSortOrder] = useState("desc");
  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  return (
    <StateContext.Provider
      value={{
        isClicked,
        setIsClicked,
        handleClick,
        currentMode,
        setCurrentMode,
        setMode,
        handleLogoClick,
        handleIconClick,
        setSelectedIcon,
        selectedIcon,
        handleResetSeletedIcon,
        selectedDateTime,
        setSelectedDateTime,
        validDevices: filteredDevices,
        setSelectedFilter,
        selectedFilter,
        depots,
        depotsUpdated,
        setDepotsUpdated,
        selectedDepot,
        setSelectedDepot,
        selectedEinheit,
        setSelectedEinheit,
        warnings,
        setWarnings,
        isArchiveOpen,
        handleArchiveOpen,
        handleArchiveClose,
        isDepotCreateOpen,
        handleDepotCreateOpen,
        handleDepotCreateClose,
        isDepotEditOpen,
        handleDepotEditOpen,
        handleDepotEditClose,
        isHelpOpen,
        handleHelpOpen,
        handleHelpClose,
        isCamaraOpen,
        setCamaraOpen,
        handleCamaraOpen,
        handleCamaraClose,
        currentImageUrl,
        fetchWarnungen,
        setConfirmationOpen,
        isConfirmationOpen,
        openConfirmationWindow,
        downloadReport,
        isPopWindowOpen,
        setPopWindowOpen,
        sortOrder,
        toggleSortOrder,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);

import React, { useEffect, useRef } from "react";
import { MapContainer as LeafletMap, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import containerIcon from "../data/wechselbrueckecolored.svg";
import inactiveContainerIcon from "../data/inactive5.svg";
import fremdnutzungUnitIcon from "../data/fremdnutzungUnit2.svg";

const MapUpdater = ({ center, zoom, devices, depots, fremdnutzungs, truckIcon, getWarehouseIcon, fremdnutzungIcon, clusterType, waypoints, onDepotSelect, handleDepotClick, handleEinheitClick }) => {
  const map = useMap();
  // Re-centers map when center prop changes
  useEffect(() => {
    map.flyTo(center, zoom, { animate: true, duration: 0.8 });
  }, [center, zoom, map]);

  useEffect(() => {
    const leafletMarkerCluster = require("leaflet.markercluster");
    let depotGroup = null; 
    let deviceGroup = null;
    let fremdnutzungGroup = null;
    let polyline = null;
    let waypointsGroup = L.layerGroup().addTo(map);

    // Red-blue clustering for the Home page map
    if (clusterType === 'red-blue') { 
      // Defines the Blue icon for the depots
      depotGroup = new leafletMarkerCluster.MarkerClusterGroup({
        iconCreateFunction: (cluster) => {
          return L.divIcon({ html: '<div style="background-color: #4b5563; color: white; width: 30px; height: 30px; text-align: center; line-height: 30px; border-radius: 50%; border: 1px solid white">' + cluster.getChildCount() + '</div>', 
          className: 'marker-cluster' });
        }
      }).addTo(map);
      // Defines the Red icon for the devices
      deviceGroup = new leafletMarkerCluster.MarkerClusterGroup({
        iconCreateFunction: (cluster) => {
          return L.divIcon({ html: '<div style="background-color: #A82F42; color: white; width: 30px; height: 30px; text-align: center; line-height: 30px; border-radius: 50%; border: 1px solid white;">' + cluster.getChildCount() + '</div>', 
          className: 'marker-cluster' });
        }
      }).addTo(map);
      // Defines the icon for the fremdnutzungs
      fremdnutzungGroup = L.layerGroup().addTo(map);

      // Adds the devices to the map
      devices.forEach((device) => {
        if (device) {
          const icon = truckIcon(device.power, device.activity);
          const marker = L.marker([device.latitude, device.longitude], { icon });
          marker.bindTooltip(`${device.name} <br /> ${new Date(device.timestamp).toLocaleString("de-DE")}`, { 
            permanent: false,
            direction: 'top',
            opacity: 0.9 }
          );
          marker.on('dblclick', () => {
            if (typeof handleEinheitClick === 'function') {
              handleEinheitClick(device);
            }
          });
          deviceGroup.addLayer(marker);
          // Adds the waypoints (route path) for the unit if they are available
          if (waypoints.length > 0) {
            // If a polyline already exists, remove it from the map
            if (polyline) {
              map.removeLayer(polyline);
            }
            polyline = L.polyline(waypoints, { color: 'blue' }).addTo(map);
            map.fitBounds(polyline.getBounds(), { padding: [50, 50] });
          }
        }
      });
      // Adds the depots to the map
      depots.forEach((depot) => {
        if (depot) {
          const icon = getWarehouseIcon(depot.type);
          const marker = L.marker([depot.latitude, depot.longitude], { icon });
          marker.bindTooltip(depot.name, { permanent: false, direction: 'top', opacity: 0.9 });
          marker.on('click', () => {
            if (typeof onDepotSelect === 'function') { 
              onDepotSelect(depot);
            }
          });
          marker.on('dblclick', () => {
            if (typeof handleDepotClick === 'function') {
              handleDepotClick(depot);
            }
          });
          depotGroup.addLayer(marker);
        }
      });
      // Adds the fremdnutzungs to the map
      fremdnutzungs.forEach((fremdnutzung) => {
        if (fremdnutzung && fremdnutzung.latitude !== undefined && fremdnutzung.longitude !== undefined) {
          const marker = L.marker([fremdnutzung.latitude, fremdnutzung.longitude], { icon: fremdnutzungIcon });
          marker.bindTooltip(`${fremdnutzung.name} <br /> ${new Date(fremdnutzung.timestamp).toLocaleString("de-DE")}`, { permanent: false, direction: 'top', opacity: 0.9 });
          fremdnutzungGroup.addLayer(marker);
        }
      });

      // Process waypoints
      waypoints.forEach((waypoint) => {
        const [latitude, longitude, time] = waypoint;

        const marker = L.marker([latitude, longitude]);
        const timestamp = new Date(time).toLocaleString("de-DE");
        marker.bindTooltip(`${timestamp}`, { permanent: false, direction: 'top', opacity: 0.8 });
        waypointsGroup.addLayer(marker);
      });

    // Default without clustering for the Einheiten page
    } else {
      depotGroup = L.layerGroup().addTo(map); // Leaflet layer group for depots
      deviceGroup = L.layerGroup().addTo(map); // Leaflet layer group for devices
      fremdnutzungGroup = L.layerGroup().addTo(map); // Leaflet layer group for fremdnutzungs
      // Adds the devices to the map
      devices.forEach((device) => {
        if (device) {
          const icon = truckIcon(device.power, device.activity);
          const marker = L.marker([device.latitude, device.longitude], { icon });
          marker.bindTooltip(`${device.name} <br /> ${new Date(device.timestamp).toLocaleString("de-DE")}`, { 
            permanent: false,
            direction: 'top',
            opacity: 0.9 }
          );
          marker.on('dblclick', () => {
            if (typeof handleEinheitClick === 'function') {
              handleEinheitClick(device);
            }
          });
          deviceGroup.addLayer(marker);
          // Adds the waypoints (route path) for the unit if they are available
          if (waypoints.length > 0) {
            // If a polyline already exists, remove it from the map
            if (polyline) {
              map.removeLayer(polyline);
            }
            polyline = L.polyline(waypoints, { color: '#A82F42' }).addTo(map);
            map.fitBounds(polyline.getBounds(), { padding: [50, 50] });
          }
        }
      });
      // Adds the depots to the map
      depots.forEach((depot) => {
        if (depot) {
          const icon = getWarehouseIcon(depot.type);
          const marker = L.marker([depot.latitude, depot.longitude], { icon });
          marker.bindTooltip(depot.name, { permanent: false, direction: 'top', opacity: 0.9 });
          marker.on('click', () => {
            if (typeof onDepotSelect === 'function') { 
              onDepotSelect(depot);
            }
          });
          marker.on('dblclick', () => {
            if (typeof handleDepotClick === 'function') {
              handleDepotClick(depot);
            }
          });
          depotGroup.addLayer(marker);
        }
      });
      // Adds the fremdnutzungs to the map
      fremdnutzungs.forEach((fremdnutzung) => {
        if (fremdnutzung) {
          const marker = L.marker([fremdnutzung.latitude, fremdnutzung.longitude], { icon: fremdnutzungIcon });
          marker.bindTooltip(`${fremdnutzung.name} <br /> ${new Date(fremdnutzung.timestamp).toLocaleString("de-DE")}`, { permanent: false, direction: 'top', opacity: 0.9 });
          fremdnutzungGroup.addLayer(marker);
        }
      });
    }
    // Removes the layers from the map when the component is unmounted
    return function cleanup() {
      depotGroup.clearLayers();
      deviceGroup.clearLayers();
      fremdnutzungGroup.clearLayers();
      waypointsGroup.clearLayers();
      
      if (polyline) {
        map.removeLayer(polyline);
      }
    };   
  }, [map, devices, depots, fremdnutzungs, truckIcon, getWarehouseIcon, fremdnutzungIcon, clusterType, waypoints, onDepotSelect, handleDepotClick, handleEinheitClick]);

    return null;   
};

// MapContainer component
const MapContainer = ({ devices, depots, fremdnutzungs, center, zoom, clusterType, waypoints, onDepotSelect, handleDepotClick, handleEinheitClick }) => {
  
  const mapRef = useRef();
  // Truck Icon
  const truckIcon = (power, activity) => {
    const icon = (power && activity) ? containerIcon : inactiveContainerIcon;
    return L.icon({
      iconUrl: icon,
      iconSize: [35, 28],
      iconAnchor: [12, 12],
      className: 'custom-truck-icon'
    });
  };

  // Warehouse Icon Function
  const getWarehouseIcon = (type) => {
    const color = type === 'Depot' ? '#4b5563' : '#e77e00';
    return L.divIcon({
      html: `<i class="fas fa-warehouse" style="font-size: 24px; color: ${color}; text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;"></i>`,
      iconSize: [12, 12],
      iconAnchor: [12, 12],
      className: 'custom-warehouse-icon'
    });
  };
  // Fremdnutzung Icon
  const fremdnutzungIcon = L.icon({
    iconUrl: fremdnutzungUnitIcon,
    iconSize: [24, 24],
    iconAnchor: [12, 12],
    className: 'custom-warning-icon'
  });

  useEffect(() => {
    const L = require('leaflet');
    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });
    // Invalidates the map size when the center changes. Re centering the map after map size changes
    if (mapRef.current) {
      const mapInstance = mapRef.current;
      const timeoutId = setTimeout(() => {
        mapInstance.invalidateSize();
      }, 100);  //const mapInstance = mapRef.current.leafletElement; // Or however you access the Leaflet map instance
                //mapInstance.flyTo(center, zoom, {animate: true, duration: 0.5});
      // Cleanup function: clear timeout on component unmount
      return () => clearTimeout(timeoutId);
    }
  }, [center]);

  return (
    <LeafletMap ref={mapRef} center={center} zoom={zoom} className="rounded-md h-full " style={{ width: "100%" }}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <MapUpdater 
      center={center}
      zoom={zoom} 
      devices={devices} 
      depots={depots} 
      fremdnutzungs={fremdnutzungs}
      truckIcon={truckIcon} 
      getWarehouseIcon={getWarehouseIcon} 
      fremdnutzungIcon={fremdnutzungIcon} 
      clusterType={clusterType} 
      waypoints={waypoints} 
      onDepotSelect={onDepotSelect}
      handleDepotClick={handleDepotClick}
      handleEinheitClick={handleEinheitClick} />
    </LeafletMap>
  );
};

export default MapContainer;
import React from 'react';
import { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiOutlineSearch } from 'react-icons/hi';
import { useStateContext } from '../contexts/ContextProvider';
import { toast } from 'react-toastify';

const Searchbar = () => {
  const { depots, validDevices, setSelectedDepot, setSelectedEinheit, handleIconClick } = useStateContext(); // get the depots and units from the context

  const depotNames = useMemo(() => depots.map((depot) => depot.name), [depots]);
  const validDeviceNames = useMemo(() => validDevices.map((device) => device.name), [validDevices]);

  const [display, setDisplay] = useState(false); // state to control display of autocomplete dropdown list
  const [options, setOptions] = useState([]);  // state to hold the filtered options
  const wrapperRef = useRef(null); // ref for the wrapper 

  const [searchTerm, setSearchTerm] = useState(''); // default to empty string
  
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const navigate = useNavigate();

  const handleSearchClick = () => {
    if (depotNames.includes(searchTerm)) {
        const depotObj = depots.find(depot => depot.name === searchTerm);
        setSelectedDepot(depotObj);
        handleIconClick('status');
        navigate(`/status`);
    } else if (validDeviceNames.includes(searchTerm)) {
        const deviceObj = validDevices.find(device => device.name === searchTerm);
        setSelectedEinheit(deviceObj);
        handleIconClick('units');
        navigate(`/einheiten`);
    } else {
      toast.error('Keine Ergebnisse gefunden, bitte versuchen Sie es erneut.', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setIsSearchClicked(!isSearchClicked);
  };

  useEffect(() => {
    const allNames = [...depotNames, ...validDeviceNames];
    if (searchTerm === '') {
      setOptions(allNames);
    } else {
      const results = [...depotNames, ...validDeviceNames].filter((name) => 
        name.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      );
      setOptions(results);
    }
  }, [searchTerm, depotNames, validDeviceNames]);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClickOutside = e => {
    const {current: wrap} = wrapperRef;
    if (wrap && !wrap.contains(e.target)) {
      setDisplay(false);
    }
  };

  const updatePokeDex = (pokeName) => {
    setSearchTerm(pokeName);
    setDisplay(false);
    //handleSearchClick();
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    if(e.target.value === '') {
      setOptions([]);
    }
  };

  return (
    <div  
      ref={wrapperRef} 
      className="relative flex flex-col items-center bg-gray-200 dark:bg-slate-700 rounded-lg focus-within:ring-2 focus-within:ring-gray-400 dark:focus-within:ring-slate-400 z-[500]"
    >
      <div className="flex items-center w-full bg-transparent">
        <input
          type="text"
          placeholder="Wechselbrücke/Depots"
          value={searchTerm}
          onClick={() => setDisplay(true)}
          onChange={handleChange}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleSearchClick();
              event.preventDefault();  // prevent the form from submitting and causing page reload
            }
          }}
          className="w-full focus:outline-none p-2 rounded-lg bg-transparent text-sm text-gray-600 dark:text-gray-200 placeholder-gray-500 dark:placeholder-white flex-grow placeholder:text-sm"
        />
        <button onClick={handleSearchClick} className="focus:outline-none p-2 bg-transparent border-l border-gray-300 dark:border-slate-600 rounded-r"
        >
          <HiOutlineSearch className="text-gray-600 dark:text-slate-200" />
        </button>
      </div>
      {display && options && options.length > 0 && (
        <div className="w-full max-h-[50vh] mt-1 text-sm overflow-auto bg-gray-100 dark:bg-slate-600 dark:text-white absolute top-full left-0 rounded-lg shadow  focus:outline-none">
          {options.map((name, i) => {
            return (
              <div 
                onClick={() => updatePokeDex(name)} 
                key={i} 
                className="option px-3 py-1 cursor-pointer hover:bg-gray-300 dark:hover:bg-slate-700 hover:rounded-lg"
              >
                {name}
              </div>
            )
          })}
        </div>
      )}
    </div>
  );
}

export default Searchbar;
import React, { useEffect } from 'react';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { useStateContext } from '../contexts/ContextProvider';

const darkStyles = [
  "material-dark",
  "material-dark-buttons",
  "material-dark-inputs",
  "material-dark-popups",
  "material-dark-react-calendars",
];

const lightStyles = [
  "material",
  "material-buttons",
  "material-inputs",
  "material-popups",
  "material-react-calendars",
];

const DateTimePicker = (props) => {
  const { id, label, value, onChanged, onDatePickerBlur, min } = props;
  const { currentMode } = useStateContext();

  const handleChange = (e) => {
    if (onChanged) {
      onChanged(id, e && e.value ? e.value : null)
    }
  };

  const handleBlur = (e) => {
    if (onDatePickerBlur) {
      onDatePickerBlur(id);
    }
  };

  useEffect(() => {
    async function createStylesheet(cssFilename) {
      const href = `${process.env.PUBLIC_URL}/styles/${cssFilename}.css`;
      const link = document.createElement("link");
      link.id = cssFilename.split("/")[2];
      link.href = `${href}`;
      link.rel = "stylesheet";
      document.head.appendChild(link);
    }
  
    function enableStylesheet(filename) {
      const fileref = document.getElementById(filename);
      if (fileref) {
        fileref.disabled = false;
      }
    }
  
    function disableStylesheet(filename) {
      const fileref = document.getElementById(filename);
      if (fileref) {
        fileref.disabled = true;
      }
    }
  
    if (currentMode === "Dark") {
      darkStyles.forEach((filename) => createStylesheet(filename));
      darkStyles.forEach((filename) => enableStylesheet(filename));
      lightStyles.forEach((filename) => disableStylesheet(filename));
    } else {
      lightStyles.forEach((filename) => createStylesheet(filename));
      lightStyles.forEach((filename) => enableStylesheet(filename));
      darkStyles.forEach((filename) => disableStylesheet(filename));
    }
  }, [currentMode]);

  return (
    <div className="py-2" style={{ width: "200px", height: "45px" }}>
      <DateTimePickerComponent
      placeholder={label || "Choose date and time"}
      value={value}
      format={"dd/MM/yyyy HH:mm"}
      min = {min}
      onChange={handleChange}
      onBlur={handleBlur}
      max={new Date()}
      />
    </div>
  );
};

 export default DateTimePicker;
import React from 'react';
import { ListKunden } from '../components';
import { useStateContext } from '../contexts/ContextProvider';

const Kunden = () => {
  const { depots } = useStateContext();

  return (
    <div className="mx-4 flex">
      <div className="flex flex-col mr-2 mb-2 h-screen w-full overflow-auto max-h-screen">
        <ListKunden 
          depots={depots}  
        />
      </div>
    </div>
  )
}

export default Kunden
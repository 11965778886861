import React from 'react';

const ListContainer = ({ containers, onSelect, selectedContainer }) => {
  return (
    <div className="w-full max-h-screen overflow-y-auto pl-2 bg-gray-200 dark:bg-slate-800 dark:text-white rounded-lg shadow-md">
      <div className="flex justify-between items-center sticky top-0 bg-gray-200 dark:bg-slate-800 z-10 p-2">
        <h3 className="font-bold">Wählen Sie eine Wechselbrücke aus: 
          <span className='text-gray-600 dark:text-gray-200 font-normal'> {containers.length} </span> 
        </h3>
      </div>
      <div className="overflow-y-auto">
        <ul>
          {containers.map((container) => (
            <li
              key={container.id}
              onClick={() => onSelect(container)}
              className={`px-4 py-2 rounded cursor-pointer transition-colors duration-100 hover:bg-gray-300 dark:hover:bg-slate-700 ${selectedContainer && selectedContainer.id === container.id 
                ? 'bg-gray-300 dark:bg-slate-500' 
                : 'hover:bg-gray-300 dark:hover:bg-slate-500'}
                `}
            >
              {container.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ListContainer;
import React, { useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import axios from "axios";
import { toast } from "react-toastify";

const EditDepot = ({ setDepotsUpdated}) => {
  const { handleDepotEditClose, depots } = useStateContext();

  // Initialize state for form inputs
  const [selectedDepot, setSelectedDepot] = useState("");
  const [depotType, setDepotType] = useState(""); // Depot oder Tagabholer
  const [radius, setRadius] = useState(800); // Radius default 800m
  const [operator, setOperator] = useState(""); // DPD, WBD, Zwischenlager
  const [unitCount, setUnitCount] = useState(0); // Anzahl der Einheiten
  const [isActive, setIsActive] = useState(false); // Aktiv oder Inaktiv

  // Handle the POST request to the API
  const handleDepotEdit = async () => {
    
    // Check if all fields are filled
    if (
      !selectedDepot ||
      depotType === "" ||
      radius === "" ||
      operator === "" ||
      unitCount === ""
    ) {
      toast.error("Bitte füllen Sie alle Felder aus.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
      return;
    }

    const dataToSend = {
      name: selectedDepot,
      type: depotType,
      radius,
      operator,
      unit_count: unitCount,
      active: isActive,
    };

    try {
      const response = await axios.post(
        "https://clam-backend.pts.space/telemetry/alterdepot",
        dataToSend,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      toast.success(response.data.message, {
        // POST Response Message "success" if everything went well
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      handleDepotEditClose();
      setDepotsUpdated(true);
    } catch (error) {
      console.error(error);
      // Show an error message
      toast.error(
        "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };

  return (
    <div className="bg-half-transparent z-[10000] h-screen w-full fixed nav-item top-0 left-0 flex justify-center">
      <div className="mt-12 rounded-lg h-fit text-gray-800 dark:text-white bg-white dark:bg-slate-800 w-6/12 shadow">
        <div className="flex justify-between items-center p-4 ml-4">
          <p className="font-semibold text-lg flex-grow text-center">
            Depot Aktualisieren
          </p>
        </div>
        {/* Fill Formular for the creation of a Depot */}
        <div className="flex flex-col border-t-1 border-gray-400 dark:border-slate-400 p-2">
          {/* Formular Input */}

          {/* Name, cannot be changed */}
          <div className="p-2 pl-4">
            <label className="block text-sm font-semibold text-gray-700">
              Depot Name
            </label>
            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-400 bg-gray-200 dark:bg-slate-700 rounded-md shadow-sm focus:outline-none focus-within:ring-2 focus-within:ring-gray-400 dark:focus-within:ring-slate-400 text-md"
              value={selectedDepot ? selectedDepot.name : ""}
              onChange={(e) => {
                const depotName = e.target.value;
                const depot = depots.find((d) => d.name === depotName);
                setSelectedDepot(depot.name);
                setDepotType(depot.type);
                setOperator(depot.operator);
                setUnitCount(depot.units);
                setIsActive(depot.activity);
              }}
            >
              <option value="" disabled>Wählen Sie ein Depot</option>
              {depots.map((depot, index) => (
                <option key={index} value={depot.name}>
                  {depot.name}
                </option>
              ))}
            </select>
          </div>

          {/* Type, can be Depot or Tagabholer */}
          <div className="p-2 pl-4">
            <label className="block text-sm font-semibold text-gray-700">
              Depot Typ
            </label>
            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-400 bg-gray-200 dark:bg-slate-700 rounded-md shadow-sm focus:outline-none focus-within:ring-2 focus-within:ring-gray-400 dark:focus-within:ring-slate-400 text-md"
              value={depotType || ""}
              onChange={(e) => setDepotType(e.target.value)}
            >
              <option value="" disabled>Wählen Sie einen Typ</option>
              <option value="Depot">Depot</option>
              <option value="Tagabholer">Tagabholer</option>
              <option value="Zwischenlager">Zwischenlager</option>
            </select>
          </div>

          {/* Radius, default 800m 
          <div className="p-2 pl-4">
            <label className="block text-sm font-semibold text-gray-700">
              Radius
            </label>
            <input
              className="mt-1 block w-full py-2 px-3 border border-gray-400 bg-gray-200 dark:bg-slate-700 rounded-md shadow-sm focus:outline-none focus-within:ring-2 focus-within:ring-gray-400 dark:focus-within:ring-slate-400 text-md"
              type="number"
              value={radius}
              onChange={(e) => setRadius(e.target.value)}
              placeholder="Geben Sie einen Radius in m ein"
            />
          </div>
        */}
          {/* Operator, can be DPD, WBD or Zwischenlager */}
          <div className="p-2 pl-4">
            <label className="block text-sm font-semibold text-gray-700">
              Betreiber
            </label>
            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-400 bg-gray-200 dark:bg-slate-700 rounded-md shadow-sm focus:outline-none focus-within:ring-2 focus-within:ring-gray-400 dark:focus-within:ring-slate-400 text-md"
              value={operator || ""}
              onChange={(e) => setOperator(e.target.value)}
            >
              <option value="" disabled>Wählen Sie einen Betreiber</option>
              <option value="DPD">DPD</option>
              <option value="BWD">BWD</option>
              <option value="Zeitfracht">Zeitfracht</option>
              {/*<option value="pts">PTS</option>*/}
            </select>
          </div>

          {/* Unit Count, default 0 */}
          <div className="p-2 pl-4">
            <label className="block text-sm font-semibold text-gray-700">
              Bestand
            </label>
            <input
              className="mt-1 block w-full py-2 px-3 border border-gray-400 bg-gray-200 dark:bg-slate-700 rounded-md shadow-sm focus:outline-none focus-within:ring-2 focus-within:ring-gray-400 dark:focus-within:ring-slate-400 text-md"
              type="number"
              value={unitCount || ""}
              onChange={(e) => setUnitCount(e.target.value)}
              placeholder="Anzahl Einheiten"
            />
          </div>

          {/* Activity, can be true or false */}
          <div className="p-2 pl-4">
            <label className="flex items-center ml-1  text-sm font-semibold">
              Aktiv
              <input
                className="mx-2"
                type="checkbox"
                checked={isActive || false}
                onChange={(e) => setIsActive(e.target.checked)}
              />
            </label>
          </div>
          
          {/* Buttons */}
          <div className="flex justify-center items-center text-sm p-2">
            <button
              className="bg-gray-200 hover:bg-gray-300 dark:bg-slate-700 dark:hover:bg-slate-500 px-4 py-2 rounded mr-2"
              onClick={() => handleDepotEditClose()}
            >
              Abbrechen
            </button>
            <button
              className="bg-[#A82F42] hover:bg-[#C7384E] text-white px-4 py-2 rounded"
              onClick={() => handleDepotEdit()}
            >
              Bestätigen
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDepot;

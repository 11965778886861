import React, {useState} from 'react';
import { toast } from 'react-toastify';
import { useStateContext } from '../contexts/ContextProvider';
import { ConfirmationWindow, PopWindow } from '.';
import '../styles/toast.css';

const ListWarnings = ( {title, warnings, onSelect, selectedWarning }) => {
  const { fetchWarnungen, setConfirmationOpen, isConfirmationOpen, openConfirmationWindow, downloadReport, isPopWindowOpen, setPopWindowOpen } = useStateContext();
  const warningCount = warnings.length;
  // API um Warnung zu archivieren
  const archiveWarning = async (id, misclassified) => {
    const response = await fetch('https://clam-backend.pts.space/telemetry/close', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id, misclassified: misclassified })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json(); 
    toast.success(data.message, { // POST Response Message "success"
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      }); 
    onSelect(null);
    fetchWarnungen();
    setConfirmationOpen(false);
  };
  //API um Warnung zu als falsch zu klassifizieren


  // Sort Warnungen nach Datum "locationArrival"
  const [sortOrder, setSortOrder] = useState('asc'); // Sortierung der Warnungen nach Datum "locationArrival"
  const toggleSortOrder = () => {
    setSortOrder(prevSortOrder => prevSortOrder === 'asc' ? 'desc' : 'asc');
  };
  // Searchbar für Warnungen in jeden List
  const [searchText, setSearchText] = useState('');
  // Filtern der Warnungen nach Datum "locationArrival"
  const sortedWarnings = [...warnings].sort((a, b) => {
    const dateA = new Date(a.foreignLocationArrival);
    const dateB = new Date(b.foreignLocationArrival);
    return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
  });
  // Filtern der Warnungen nach Name
  const filteredWarnings = sortedWarnings.filter(warning =>
    warning.name.toLowerCase().includes(searchText.toLowerCase())
  );
  // Confirmation Text um Fremdnutzung zu archivieren oder als falsch zu klassifizieren
  const [confirmationText, setConfirmationText] = useState("");
  // Für die POST Request um eine Fremdnutzung zu archivieren oder als falsch zu klassifizieren
  const [misclassified, setMisclassified] = useState(false);

    return (
      <div className="flex flex-col h-full overflow-auto bg-gray-200 dark:bg-slate-700 dark:text-white rounded-lg">
        <div className="flex justify-between items-center sticky top-0 bg-gray-200 dark:bg-slate-700 z-10 p-2">
          <h3 className="font-bold">{title}</h3>
            <input
              type="text"
              placeholder={`${title} Fremdnutzung suchen`}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="w-full mx-4 focus:outline-none p-2 rounded bg-gray-300 dark:bg-slate-500 text-sm text-gray-600 dark:text-gray-200 placeholder-gray-500 dark:placeholder-white flex-grow placeholder:text-sm focus-within:ring-2 focus-within:ring-gray-400 dark:focus-within:ring-slate-400"
            />
          <div className="flex items-center">
            <span className="text-gray-600 dark:text-gray-200">{warningCount}</span>
            <button onClick={toggleSortOrder} className="bg-transparent border-none cursor-pointer ml-2 ">
              <span className='text-xs'>{sortOrder === 'asc' ? '▼' : '▲'}</span>
            </button>
          </div>
        </div>
          <div className="flex-grow overflow-y-auto">
            <ul>
            {filteredWarnings.map((warning) => ( 
              <li
                key={warning.id}
                onClick={() => {
                  // If the selectedWarning is already the clicked warning, unselect it.
                  if (selectedWarning && selectedWarning.id === warning.id) {
                      onSelect(null);
                  } else {
                      onSelect(warning);
                  }
                }}
                className={`px-4 py-2 rounded cursor-pointer transition-colors duration-100 hover:bg-gray-300 dark:hover:bg-slate-500 ${selectedWarning && selectedWarning.id === warning.id
                  ? 'bg-gray-300 dark:bg-slate-500'
                  : 'hover:bg-gray-300 dark:hover:bg-slate-500'}`}
              >
                <div className="flex justify-between w-full">
                  <div>{warning.name}</div>
                  <span className='text-gray-500 dark:text-slate-400'>{new Date(warning.foreignLocationArrival).toLocaleString("de-DE")}</span>
                </div>
                {/* Displays more information if this warning is selected */}
                {selectedWarning && selectedWarning.id === warning.id  &&
                <>
                  <div className="mt-2 ml-2 text-sm flex flex-col dark:text-white">
                    {/* Unorded list with bullet points*/}
                    <div>
                      <div className='text-[16px] font-bold'>Abfahrt:</div>
                      <ul className='list-disc list-outside ml-4'>
                        <li>Abfahrt: {new Date(warning.lastDepotDeparture).toLocaleString("de-DE")}</li>
                        <li>Depot: {warning.lastDepot}</li>
                      </ul>
                    </div>
                    <div>
                      <div className='text-[16px] font-bold'>Unbekannt:</div>
                      <ul className='list-disc list-outside ml-4'>
                        <li>Ankunft: {new Date(warning.foreignLocationArrival).toLocaleString("de-DE")}</li>
                        <li>Ort: {warning.foreignLocation}</li>
                        <li>Abfahrt: {warning.foreignLocationDeparture ? new Date(warning.foreignLocationDeparture).toLocaleString("de-DE") : "Nicht bekannt."}</li>
                      </ul>
                    </div>                    
                    {/* Für die Abgeschlossen List relevant. Wenn Next Depot bekannt ist */}
                    {warning.nextDepot && warning.nextDepotArrival &&
                    <>
                    <div>
                      <div className='text-[16px] font-bold'>Ankunft:</div>
                      <ul className='list-disc list-outside ml-4'>
                        <li>Ankunft: {new Date(warning.nextDepotArrival).toLocaleString("de-DE")}</li>
                        <li>Depot: {warning.nextDepot}</li>
                      </ul>
                    </div>
                    {/* Buttons für die Abgeschlossen List */}
                    <div className="mt-2 flex gap-2 justify-center">

                      <button className="bg-[#A82F42] hover:bg-[#C7384E] text-white rounded-md py-1 text-sm px-2" onClick={(event) => {
                        event.stopPropagation();
                        setConfirmationText("Sind Sie sicher, dass Sie diese Fremdnutzung archivieren wollen?");
                        setMisclassified(false);
                        openConfirmationWindow();
                      }}>Bearbeitet</button>

                      <button className="bg-[#A82F42] hover:bg-[#C7384E] text-white rounded-md py-1 text-sm px-2" onClick={(event) => {
                        event.stopPropagation();
                        setConfirmationText("Sind Sie sicher, dass Sie diese Fremdnutzung als 'Keine Fremdnutzung' markieren wollen?");
                        setMisclassified(true);
                        openConfirmationWindow();
                      }}>Keine Fremdnutzung</button>

                      <button className="bg-[#A82F42] hover:bg-[#C7384E] text-white rounded-md py-1 text-sm px-2" onClick={(event) => {
                        event.stopPropagation();
                        downloadReport(selectedWarning.id, true)}}>
                        Report erstellen
                      </button>

                    </div>
                    {/* Confirmation Window um Fremdnutzung zu archivieren */}
                    <ConfirmationWindow 
                      isOpen={isConfirmationOpen} 
                      onConfirm={() => archiveWarning(selectedWarning.id, misclassified)} 
                      text={confirmationText}
                      onCancel={() => setConfirmationOpen(false)}
                       />
                    {/* PopUp Window um mehrere Reports zu downloaden */}
                    <PopWindow 
                      isOpen={isPopWindowOpen} 
                      onConfirm={() => {
                        setPopWindowOpen(false);
                        downloadReport(selectedWarning.id, false);
                      }} 
                    />
                  </>
                  }
                  </div>
                </>
                }
              </li>
            ))}
            </ul>
          </div>
        </div>
      );
}

export default ListWarnings;
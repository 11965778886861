import React from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import containerIcon from "../data/wechselbrueckecolored.svg";
import fremdnutzungUnitIcon from "../data/fremdnutzungUnit2.svg";
import { FaWarehouse } from "react-icons/fa";
import inactiveContainerIcon from "../data/inactive5.svg";

const LegendeMenu = () => {
  const { handleHelpClose } = useStateContext();
  return (
    <div className='bg-half-transparent z-[8000] h-screen w-full fixed nav-item left-0 flex justify-center'>
        <div className='mt-16 rounded-lg h-fit text-gray-800 dark:text-white bg-gray-100 dark:bg-slate-800 w-3/12'>
            <div className='flex justify-between items-center p-4 ml-4'>
                <p className='font-semibold text-lg flex-grow text-center'>Legende</p>
            </div>
            <div className='flex-col border-t-1 border-gray-700 dark:border-slate-200 p-4 pb-2'>
            <div >
              <ul className='ml-2'>
                <li className='flex items-center gap-4  p-2'>
                  <img src={containerIcon} className='w-7 h-6' alt="Container Icon" /> <span> Wechselbrücke</span>
                </li>
                <li className='flex items-center gap-4  p-2'>
                  <img src={inactiveContainerIcon} className='w-7 h-6' alt="Inactive Container Icon" /> <span> Inaktive Wechselbrücke <br /> <span className='text-xs italic font-bold'>Seit 3 Tagen nicht mehr bewegt.</span> </span>
                </li>
                <li className='flex items-center gap-4  p-2'>
                  <img src={fremdnutzungUnitIcon} className='w-6 h-6 ' alt="Fremdnutzung Icon" /> <span> Fremdnutzung</span>
                </li>
                <li className='flex items-center gap-4  p-2'>
                  <FaWarehouse className='w-6 h-6' style={{color: '#4b5563'}} /> <span> Depot</span>
                </li>
                <li className='flex items-center gap-4  p-2'>
                  <FaWarehouse className='w-6 h-6' style={{color: '#e77e00'}} /> <span> Tagabholer</span>
                </li>
              </ul>
            </div>
            <div className='flex justify-center items-center p-2'>
              <button
                className="bg-[#A82F42] hover:bg-[#C7384E] text-white text-sm px-4 py-2 rounded"
                onClick={()=> handleHelpClose()}
                >Schließen
              </button>
            </div>
          </div>
         </div>
    </div>
  )
}

export default LegendeMenu;
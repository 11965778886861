import React from 'react';

const TableStatus = ({ data, depotObj}) => {
  if (!depotObj) {
    return null;
  }
  const noData = data.length === 0;
  return (
    <div className="flex flex-col bg-gray-200 dark:bg-slate-700 shadow-md max-h-full rounded-md p-2 mb-4">
      <div className='mb-1'>
        <table className="table-auto w-full ">
          <thead>
            <tr className='text-center'>
              <th className="border-2 border-gray-700 dark:border-slate-100 px-4 py-2 dark:text-white w-1/3">
                <div className='flex justify-center'>
                  <span className='mr-1'>Wechselbrücken in:</span>
                  <span className='font-normal mr-6'>{depotObj.name}</span>
                  <span className='text-gray-500 font-light justify-right items-right'>{noData ? 0 : data.length}</span>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>  
      
      <div className="overflow-auto flex-grow">
        <table className="table-auto w-full"> 
          <tbody>
            {!noData && data.map((item, index) => (
              <tr key={index} className={`bg-gray-300 dark:bg-slate-800 text-center dark:text-white`}>
                <td className="border border-gray-600 dark:border-slate-500 px-4 py-2">{item.customer_id}</td>
              </tr>
            ))}
            {noData && (
              <tr className="bg-gray-300 dark:bg-slate-800 text-center dark:text-white">
              <td className="border border-gray-600 dark:border-slate-500 px-4 py-2 w-full" colSpan="3">Keine Wechselbrücke mit CLAM Einheit für den ausgewählten Zeitraum zu finden.</td>
            </tr>
            )}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default TableStatus;
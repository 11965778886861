import React from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { IoMdHelpCircleOutline } from "react-icons/io";


const Menu = () => {
  const { setMode, currentMode, handleClick, handleArchiveOpen, handleDepotCreateOpen, setSelectedFilter, selectedFilter, handleHelpOpen } = useStateContext();

  return (
    <div className='bg-half-transparent z-[10000] h-screen w-full fixed nav-item top-0 right-0'>
      <div className='float-right mt-16 rounded-lg h-fit text-gray-900 dark:text-white bg-gray-100 dark:bg-slate-800 shadow w-1/5'>
        <div className='flex justify-between items-center p-4 ml-4'>
          <p className='font-semibold text-lg flex-grow text-center'>Menu</p>
        </div>

        {/* Theme Selection */}
        <div className='flex-col border-t-1 border-gray-700 dark:border-slate-200 p-4'>
          <p className='font-medium text-[16px] flex-grow'>Theme Einstellungen</p>
          <div className='flex items-center space-x-5 mt-2 pl-2'>
            <div className='flex items-center'>
              {/* Light mode */}
              <input
                type="radio"
                id='light'
                name='theme'
                value='Light'
                className='cursor-pointer'
                onChange={setMode}
                checked={currentMode === 'Light'}
              />
              <label htmlFor="light" className='ml-2 text-sm cursor-pointer hover:font-bold'>
                Light
              </label>
            </div>
            
            <div className='flex items-center'>
              {/* Dark mode */}
              <input  
                type="radio"
                id='dark'
                name='theme'
                value='Dark'
                className='cursor-pointer'
                onChange={setMode}
                checked={currentMode === 'Dark'}
              />
              <label htmlFor="dark" className='ml-2 text-sm cursor-pointer hover:font-bold'>
                Dark
              </label>
            </div>
          </div>
        </div>

        {/* Wechselbrücke filtern */}
        <div className='flex-col border-t-1 border-gray-400 dark:border-slate-600 p-4'>
          <p className='font-medium text-[16px] flex-grow'>Wechselbrücke filtern</p>
          <div className='flex items-center space-x-5 mt-2 pl-2'>
            <div className='flex items-center'>
              {/* D160 Wechselbrücke */}
              <input
                type="radio"
                id='d160'
                name='wechselbrücke'
                value='D160'
                className='cursor-pointer'
                onChange={()=> setSelectedFilter('D160')}
                checked={selectedFilter === 'D160'}
              />
              <label htmlFor="d160" className='ml-2 text-sm cursor-pointer hover:font-bold'>
                D160
              </label>
            </div>

            <div className='flex items-center'>
              {/* ZF Wechselbrücke */}
              <input
                type="radio"
                id='zf'
                name='wechselbrücke'
                value='ZF'
                className='cursor-pointer'
                onChange={()=> setSelectedFilter('ZF')}
                checked={selectedFilter === 'ZF'}
              />
              <label htmlFor="zf" className='ml-2 text-sm cursor-pointer hover:font-bold'>
                ZF
              </label>
            </div>

            <div className='flex items-center'>
              {/* Alle Wechselbrücken */}
              <input
                type="radio"
                id='alle'
                name='wechselbrücke'
                value='Alle'
                className='cursor-pointer'
                onChange={()=> setSelectedFilter('Alle')}
                checked={selectedFilter === 'Alle'}
              />
              <label htmlFor="alle" className='ml-2 text-sm cursor-pointer hover:font-bold'>
                Alle
              </label>
            </div>
          </div>
        </div>

        {/* Depots verwalten */}
        <div className='flex-col border-t-1 border-gray-400 dark:border-slate-600 p-4'>
          <p className='font-medium text-[16px] flex-grow'>Depots verwalten</p>
          <button onClick={handleDepotCreateOpen} className='text-sm pl-2 mt-2 hover:font-bold'>Erstellen</button>
        </div>

        {/* Fremdnutzung archive */}
        <div className='flex-col border-t-1 border-gray-400 dark:border-slate-600 p-4'>
          <p className='font-medium text-[16px] flex-grow'>Fremdnutzung Archive</p>
          <button onClick={handleArchiveOpen} className='text-sm pl-2 mt-2 hover:font-bold'>Suchen</button>
        </div>

        {/* Legende */}
        <div className='flex border-t-1 border-gray-400 dark:border-slate-600 p-4 pb-2'>
          <p className='font-medium text-[16px] flex-grow'>Legende</p>
          <button onClick={handleHelpOpen} className='text-2xl pl-2'>< IoMdHelpCircleOutline /></button>
        </div>

        <div className='flex justify-center items-center p-2'>
          <button
            className="bg-[#A82F42] hover:bg-[#C7384E] text-white text-sm px-4 py-2 rounded"
            onClick={()=> handleClick('menu', false)}
            >Schließen
          </button>
        </div>
        
      </div>
    </div>
  )
}
export default Menu;
import React, { useCallback } from 'react';
import { useState, useEffect } from 'react';
import {  ListDepot, MapContainer, TableStatus } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { toast } from 'react-toastify';
import '../styles/toast.css'
import { useLocation } from 'react-router-dom';

const Status = () => {
  // Defines default center (coordinate of Germany)
  const [center, setCenter] = useState([50.7169, 10.0371]); // Set it to the desired center of the map
  const [zoom, setZoom] = useState(6);  // Set the initial zoom level of the map
  
  const { depots, selectedDateTime, setSelectedDateTime, selectedDepot, setSelectedDepot, selectedFilter } = useStateContext();
  const containers = [null];

  // UseState for the TableStatus component
  const [showTableStatus, setShowTableStatus] = useState(false);
  // Status data (test) for the TableStatus component
  const [statusData, setStatusData] = useState([]);

  const location = useLocation();

  const fetchStatusData = useCallback((depot) => { // fetchStatusData function
    // Convert the selected start time to Unix time
    const endTimeUnix = Math.floor(selectedDateTime.endTime.getTime() / 1000);

    const dataToSend = {
      depot, 
      time: endTimeUnix, 
    };
    // Send a POST request to the API
    axios.post("https://clam-backend.pts.space/telemetry/depotstatus", dataToSend, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
    .then((response) => {
      const responseData = JSON.parse(response.data); // Parse string to JSON
    
      if (responseData && Array.isArray(responseData.data)) { // Check if response data is an array
        const transformedData = responseData.data.map((item) => {
          
          const itemObj = {};
          responseData.columns.forEach((column, index) => {
            itemObj[column] = item[index];
          });
          return itemObj;
        });
        setStatusData(transformedData);
        setShowTableStatus(true);
        setCenter([selectedDepot.latitude, selectedDepot.longitude]);
        setZoom(12);
      } else {
        console.error("Unexpected API response structure");
      }
      
    });
  }, [selectedDateTime.endTime, selectedDepot]);

  useEffect(() => {
    if  (!selectedDateTime.endTime && selectedDepot === null) {
      setSelectedDateTime({
        ...selectedDateTime,
        endTime: new Date(),
      });

      toast.info(`Ergebnisse werden für diese Zeitraum angezeit: ${new Date().toLocaleString("de-DE")}`, {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });

    } else if (selectedDateTime.endTime && selectedDepot !== null) {
      fetchStatusData(selectedDepot.name);

    } else if (!selectedDateTime.endTime && selectedDepot !== null) { 
      toast.warn('Bitte wählen Sie ein Enddatum und eine Uhrzeit aus.', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setStatusData([]);  
    } 
  }, [selectedDateTime.endTime, selectedDepot, fetchStatusData, setSelectedDateTime, selectedDateTime, location]);

  const handleDepotSelect = (depot) => {
    if (selectedDepot && selectedDepot.name === depot.name) {
      // Unselect the depot and hide the table if it's selected again.
      setSelectedDepot(null);
      setShowTableStatus(false);
      setCenter([50.7169, 10.0371]);
      setZoom(6);
    } else {
      // If a new depot is selected, fetch its data
      setSelectedDepot(depot);
      setShowTableStatus(true);
      setCenter([depot.latitude, depot.longitude]);
      setZoom(12);
    }
  };
  // Filter the status data by the selected filter
  let filteredStatusData = statusData;
  if (selectedFilter !== 'Alle') {
    filteredStatusData = statusData.filter((item) => item.container.includes(selectedFilter) );
  }

  return (
    <div className="mx-4 h-screen">
      <div className="flex flex-wrap md:flex-nowrap h-full">
        <div className="mr-4 mb-4 flex justify-start" style={{ width: '25%' }}>
          <ListDepot
            depots={depots}
            onSelect={handleDepotSelect}
            selectedDepot={selectedDepot}
          />
        </div>

        <div className="flex flex-col h-full w-full">
          <div className="flex-grow h-1/2 w-full mb-2">
            <MapContainer 
            devices={containers} 
            depots={selectedDepot ? [selectedDepot] : depots} 
            fremdnutzungs={[]} 
            center={ center }
            zoom={ zoom } 
            clusterType={"red-blue"} 
            waypoints={[]} 
            onDepotSelect={handleDepotSelect}/>
          </div>

          {showTableStatus && (
            <div className="h-1/2 w-full flex justify-center mb-4">
              <div className="w-full">
                <TableStatus data={filteredStatusData} depotObj={selectedDepot}/>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Status;

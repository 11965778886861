import React from "react";
import { useStateContext } from "../contexts/ContextProvider";

const CameraWindow = ({ image, unitName }) => {
  const { handleCamaraClose } = useStateContext();
  return (
    <div className="bg-half-transparent z-[8000] h-screen w-full fixed nav-item left-0 top-0 flex justify-center">
      <div className="mt-10 rounded h-fit overflow-y-auto text-gray-800 dark:text-white bg-white dark:bg-slate-800 w-6/12 shadow">
        <div className="flex justify-between items-center p-4 ml-4">
          <p className="font-semibold text-lg flex-grow text-center">
            Kamera von: {unitName}
          </p>
        </div>
        <div className="flex-col border-t-1 border-gray-400 dark:border-slate-400 p-4 pb-1">

          {image ? (
            <div className="flex justify-center items-center">
              <img src={image} alt="Wechselbruecke" className="w-full h-auto" />
            </div>
          ) : (
            <div className="flex justify-center items-center p-4">
              <p>Bilder noch nicht zur Verfügung</p>
            </div>
          )}

          <div className="flex justify-center items-center p-2 pb-2">
            <button
              className="bg-[#A82F42] hover:bg-[#C7384E] text-white text-sm px-4 py-2 rounded"
              onClick={() => handleCamaraClose()}
            >
              Schließen
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CameraWindow;
